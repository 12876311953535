import { colors } from '@constants/colors';

export const CreateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill={colors.emerald} />
    <path
      d="M10.4831 18V6H13.5169V18H10.4831ZM6 13.5169V10.4831H18V13.5169H6Z"
      fill="white"
    />
  </svg>
);
