const COMMON_KEYWORDS = {
  contexts: 'contexts',
  context: 'context',
  events: 'events',
  filter: 'filter',
  locations: 'locations',
  onboard: 'onboard',
  personas: 'personas',
  contextualizedVersion: 'contextualised-version',
  roles: 'roles',
  products: 'products',
  directory: 'directory',
  search: 'search',
  states: 'states',
  cities: 'cities',
  regions: 'regions',
  tenant: 'tenant',
  tenants: 'tenants',
  journey: 'journey',
  assets: 'assets',
  digitalAssets: 'digital-assets',
  permission: 'permission',
  newPermission: 'new-permissions',
  permissions: 'permissions',
  filters: 'filters',
  v2: 'v2',
  v3: 'v3',
  utm: 'utm',
  tasks: 'tasks',
  taskList: 'tasks-list',
  dam: 'dam',
  submitTasks: 'submit-tasks',
  delivery: 'delivery',
  ratio: 'ratio',
  publish: 'publish',
  enrich: 'enrich',
  entity: 'entity',
  updateAuthority: 'update-authority',
  insights: 'insights',
  sku: 'sku',
  skus: 'skus',
  productIds: 'ids',
  templates: 'templates',
  mappings: 'mappings',
  taxonomies: 'taxonomies',
  global: 'global',
  countries: 'countries',
  typeahead: 'typeahead',
  targetGroupsWeight: 'targetgroupsweight',
  usages: 'usages',
  bulk: 'bulk',
  guide: 'guide',
  status: 'status',
  common: 'common',
  help: 'help',
  users: 'users',
  aggregate: 'aggregate',
  settings: 'settings',
  upload: 'upload',
  url: 'url',
  prompts: 'prompts',
  general: 'general',
  update: 'update',
  generate: 'generate',
  title: 'title',
  description: 'description',
} as const;

const MODULE = {
  contextualizedProducts: '/contextualized-products',
  dam: '/dam',
  iam: '/iam',
  products: `/${COMMON_KEYWORDS.products}`,
  connector: '/connector',
  contexts: `/${COMMON_KEYWORDS.contexts}`,
  metadata: '/metadata',
  userData: '/user-data',
  geoLocation: '/geo-location/geo-location',
  richContent: '/rich-content',
  notifications: '/notifications',
  notification: '/notification',
  analytics: '/analytics',
  languages: '/languages',
  templateManager: '/template-manager',
  intelligenceCreator: '/intelligence',

  //new end point
  context: `/${COMMON_KEYWORDS.context}`,
  quickStartGuide: `/${COMMON_KEYWORDS.tenants}`,
} as const;

export const ENDPOINTS = {
  analytics: {
    products: `${MODULE.analytics}/${COMMON_KEYWORDS.products}/all`,
    productVisits: `${MODULE.analytics}/${COMMON_KEYWORDS.products}/visits`,
    cart: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/cart-value`,
    recommendations: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/recommendations`,
    atc: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/atc`,
    bounceRate: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/bounce-rate`,
    experienceScore: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/experience-score`,
    uptimeGraph: `${MODULE.analytics}/performance/uptime`,
    deliveryTimeGraph: `${MODULE.analytics}/performance/response-time`,
    totalDeliveryGraph: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/delivery-type`,
    contextDeliveryGraph: `${MODULE.analytics}/${COMMON_KEYWORDS.insights}/contexts`,
    productPerformance: `${MODULE.analytics}/performance/all`,
    aggregate: COMMON_KEYWORDS.aggregate,
    refresh: `${COMMON_KEYWORDS.aggregate}/refresh`,
  },
  context: {
    context: `${MODULE.context}/${COMMON_KEYWORDS.contexts}`,
    contexts: `${MODULE.context}${MODULE.contexts}/${COMMON_KEYWORDS.contexts}`,
    contextList: `${MODULE.context}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.filters}`,
    contextBulkDelete: `${MODULE.context}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.bulk}`,
    locationSearchTypeahead: `${MODULE.geoLocation}/${COMMON_KEYWORDS.countries}/${COMMON_KEYWORDS.search}/${COMMON_KEYWORDS.typeahead}`,
    targetGroupsWeight: `${MODULE.context}/${COMMON_KEYWORDS.targetGroupsWeight}`,
    usages: `${MODULE.context}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.usages}/${COMMON_KEYWORDS.products}`,
    utm: {
      utmList: `${MODULE.context}/${COMMON_KEYWORDS.utm}/${COMMON_KEYWORDS.filters}`,
      utm: `${MODULE.context}/${COMMON_KEYWORDS.utm}`,
      bulk: `${MODULE.context}/${COMMON_KEYWORDS.utm}/${COMMON_KEYWORDS.bulk}`,
      unlink: `${MODULE.context}/${COMMON_KEYWORDS.contexts}/unlink-utm`,
    },
  },
  contexts: {
    events: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.events}`,
    filter: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.filter}`,
    filterEvents: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.events}/${COMMON_KEYWORDS.filter}`,
    filterLocations: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.locations}/${COMMON_KEYWORDS.filter}`,
    filterPersonas: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.personas}/${COMMON_KEYWORDS.filter}`,
    locations: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.locations}`,
    personas: `${MODULE.contexts}/${COMMON_KEYWORDS.contexts}/${COMMON_KEYWORDS.personas}`,
    states: `${MODULE.geoLocation}/${COMMON_KEYWORDS.states}`,
    cities: `${MODULE.geoLocation}/${COMMON_KEYWORDS.cities}`,
    regions: `${MODULE.geoLocation}/${COMMON_KEYWORDS.regions}`,
    journey: `${MODULE.contexts}/${COMMON_KEYWORDS.journey}`,
    filterJourney: `${MODULE.contexts}/${COMMON_KEYWORDS.journey}/${COMMON_KEYWORDS.filter}`,
    utm: `${MODULE.contexts}/${COMMON_KEYWORDS.utm}`,
    utmFilter: `${MODULE.contexts}/${COMMON_KEYWORDS.utm}/${COMMON_KEYWORDS.filter}`,
    queryParams: {
      context: 'context',
      contexts: 'contexts',
    },
  },
  contextualizedProducts: {
    contextualizedProducts: MODULE.contextualizedProducts,
    contextualizedVersion: COMMON_KEYWORDS.contextualizedVersion,
    products: `${MODULE.contextualizedProducts}/${COMMON_KEYWORDS.products}`,
    tasksList: `${MODULE.contextualizedProducts}/${COMMON_KEYWORDS.taskList}`,
    contextualisedVersions: `${MODULE.contextualizedProducts}/contextualised-versions`,
    submitTasks: `${MODULE.contextualizedProducts}/${COMMON_KEYWORDS.submitTasks}`,
    settings: 'version-settings',
    deliveryRatio: `${COMMON_KEYWORDS.delivery}/${COMMON_KEYWORDS.ratio}`,
    updatePublishDate: 'update-publish-date',
    publishDate: 'publishDate',
    expiryDate: 'expiryDate',
    tasks: 'tasks',
    status: COMMON_KEYWORDS.status,
    taskFeedback: 'task-feedback',
    taskStatus: 'task-status',
    startReview: 'start-review',
    approveTask: 'approve',
    updateAssignee: 'update-assignee',
    preview: 'preview',
    filter: COMMON_KEYWORDS.filter,
    queryParams: {
      title: 'title',
      eventType: 'eventType',
      referenceVersion: 'referenceVersion',
      page: 'page',
      size: 'size',
      creationType: 'creationType',
      submitType: 'submitType',
    },
  },
  dam: {
    assets: `${MODULE.dam}/${COMMON_KEYWORDS.assets}`,
    filterList: `${MODULE.dam}/${COMMON_KEYWORDS.assets}/filter-list`,
    download: `${MODULE.dam}/${COMMON_KEYWORDS.assets}/download/all`,
    uploadTranscript: `${MODULE.dam}/${COMMON_KEYWORDS.assets}/upload-transcript`,
    assetTags: `${MODULE.dam}/tags`,
    replaceAsset: `${MODULE.dam}/${COMMON_KEYWORDS.assets}/replace`,
    removeUsage: `${MODULE.dam}/${COMMON_KEYWORDS.assets}/remove-usage-mapping`,
    search: `${MODULE.dam}/${COMMON_KEYWORDS.v2}/${COMMON_KEYWORDS.dam}/${COMMON_KEYWORDS.search}`,
    damV2: `${MODULE.dam}/${COMMON_KEYWORDS.v2}/${COMMON_KEYWORDS.dam}`,
    damV2Search: `${MODULE.dam}/${COMMON_KEYWORDS.v2}/${COMMON_KEYWORDS.dam}/${COMMON_KEYWORDS.search}`,
    damV2Filters: `${MODULE.dam}/${COMMON_KEYWORDS.v2}/${COMMON_KEYWORDS.dam}/${COMMON_KEYWORDS.filters}/${COMMON_KEYWORDS.dam}`,
    uploadUrl: `${MODULE.dam}/${COMMON_KEYWORDS.upload}/${COMMON_KEYWORDS.url}`,
    queryParams: {
      page: 'page',
      size: 'size',
      sort: 'sort',
      query: 'query',
      consumerId: 'consumerId',
      assetConsumer: 'assetConsumer',
      contentConsumer: 'contentConsumer',
    },
    v3: {
      list: `${MODULE.dam}/${COMMON_KEYWORDS.v3}/${COMMON_KEYWORDS.digitalAssets}`,
      count: `${MODULE.dam}/${COMMON_KEYWORDS.v3}/${COMMON_KEYWORDS.digitalAssets}/count`,
    },
  },
  iam: {
    activation: 'activation',
    avatar: 'avatar',
    iamRoles: `${MODULE.iam}/${COMMON_KEYWORDS.roles}`,
    permissions: 'permissions',
    roles: `${MODULE.iam}/${COMMON_KEYWORDS.roles}`,
    users: `${MODULE.iam}/${COMMON_KEYWORDS.users}`,
    usersPermissionBased: `${MODULE.iam}/${COMMON_KEYWORDS.users}/permission-based`,
    onboardStatus: `${MODULE.iam}/${COMMON_KEYWORDS.users}/onboard-status`,
    findUsers: `${MODULE.iam}/${COMMON_KEYWORDS.users}/find`,
    verificationEmail: 'verification-email',
    rolePermissions: `${MODULE.iam}/${COMMON_KEYWORDS.roles}/${COMMON_KEYWORDS.permission}`,
    entity: `${MODULE.iam}/${COMMON_KEYWORDS.newPermission}/${COMMON_KEYWORDS.permissions}`,
    updatePermissions: `${MODULE.iam}/${COMMON_KEYWORDS.newPermission}/${COMMON_KEYWORDS.updateAuthority}`,
  },
  metadata: {
    highlights: `${MODULE.metadata}/highlights`,
    segments: `${MODULE.metadata}/segments`,
    statuses: `${MODULE.metadata}/statuses`,
    ageGroups: `${MODULE.metadata}/age-groups`,
    incomeRanges: `${MODULE.metadata}/income-ranges`,
    taxonomies: `${MODULE.metadata}/taxonomies/`,
    allTaxonomies: `${MODULE.metadata}/taxonomies/root/all`,
    level2Taxonomies: `${MODULE.metadata}/taxonomies/list`,
    countries: `${MODULE.metadata}/countries`,
    locations: `${MODULE.metadata}/${COMMON_KEYWORDS.locations}`,
    locationsFilter: `${MODULE.metadata}/${COMMON_KEYWORDS.locations}/${COMMON_KEYWORDS.filter}`,
    queryParams: {
      page: 'page',
      size: 'size',
      sort: 'sort',
    },
  },
  userData: {
    recentSearches: `${MODULE.userData}/recent-searches`,
    queryParams: {
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      searchType: 'searchType',
    },
  },
  richContent: {
    base: `${MODULE.richContent}`,
    task: `${MODULE.richContent}/${COMMON_KEYWORDS.tasks}`,
    taskList: `${MODULE.richContent}/${COMMON_KEYWORDS.tasks}/${COMMON_KEYWORDS.taskList}`,
    fetchAll: `${MODULE.richContent}/${COMMON_KEYWORDS.enrich}/filter`,
    raw: `${MODULE.richContent}/raw`,
    publish: `${MODULE.richContent}/${COMMON_KEYWORDS.enrich}/${COMMON_KEYWORDS.publish}`,
    submitTasks: `${MODULE.richContent}/${COMMON_KEYWORDS.tasks}/${COMMON_KEYWORDS.submitTasks}`,
    enrich: `${MODULE.richContent}/${COMMON_KEYWORDS.enrich}`,
    removeUsage: `${MODULE.richContent}/${COMMON_KEYWORDS.enrich}/remove-usage-mapping`,
  },
  notification: {
    base: `${MODULE.notification}${MODULE.notifications}/${COMMON_KEYWORDS.filter}`,
    count: `${MODULE.notification}${MODULE.notifications}/count`,
    postNotification: `${MODULE.notification}${MODULE.notifications}`,
    update: `${MODULE.notification}${MODULE.notifications}/update/status`,
    queryParams: {
      fromStatus: 'fromStatus',
      toStatus: 'toStatus',
      notificationIds: 'notificationIds',
    },
  },
  languages: {
    translation: `${MODULE.languages}/translation`,
  },
  template: {
    globalConfig: `${MODULE.templateManager}/${COMMON_KEYWORDS.templates}/${COMMON_KEYWORDS.global}`,
    templateMappings: `${MODULE.templateManager}/${COMMON_KEYWORDS.templates}/${COMMON_KEYWORDS.mappings}`,
    queryParams: {
      sku: COMMON_KEYWORDS.sku,
    },
  },
  quickStartGuide: {
    guide: `${MODULE.quickStartGuide}/${COMMON_KEYWORDS.guide}`,
    guideStatus: `${MODULE.quickStartGuide}/${COMMON_KEYWORDS.guide}/${COMMON_KEYWORDS.status}`,
  },
  intelligenceCreator: {
    generateTestVersion: `${MODULE.intelligenceCreator}/${COMMON_KEYWORDS.generate}`,
    updateVersionTitle: `${MODULE.intelligenceCreator}/${COMMON_KEYWORDS.update}/${COMMON_KEYWORDS.title}`,
    updateVersionDescription: `${MODULE.intelligenceCreator}/${COMMON_KEYWORDS.update}/${COMMON_KEYWORDS.description}`,
    versionPrompts: `${MODULE.intelligenceCreator}/${COMMON_KEYWORDS.prompts}`,
  },
} as const;
