const MODULE = {
  dashboard: 'dashboard',
  products: 'products',
  pages: 'pages',
  digitalAssets: 'digital-assets',
  richContent: 'rich-contents',
  context: 'contexts',
  taskMaster: 'task-master',
  settings: 'settings',
  notifications: 'notifications',
  myAccount: 'my-account',
  strategyBoard: 'onboarding',
} as const;

const COMMON_KEYWORDS = {
  id: 'id',
  tab: 'tab',
  productId: 'productId',
  versionId: 'versionId',
  version: 'version',
  versions: 'versions',
  settings: 'settings',
  overview: 'overview',
  utm: 'utm',
} as const;

// TODO replace with `@route` alias and +page name export `RouteName`
export const ROUTES = {
  dashboard: {
    base: `/${MODULE.dashboard}`,
  },
  startGuide: '/start-guide',
  digitalAssets: {
    base: `/${MODULE.digitalAssets}`,
    digitalAssetsDetail: `/${MODULE.digitalAssets}/:${COMMON_KEYWORDS.id}`,
  },
  richContent: {
    base: `/${MODULE.richContent}`,
  },
  context: {
    base: `/${MODULE.context}`,
    utm: `/${MODULE.context}/${COMMON_KEYWORDS.utm}`,
    settings: {
      base: `/${MODULE.context}/${COMMON_KEYWORDS.settings}`,
      weightageSettings: `/${MODULE.context}/${COMMON_KEYWORDS.settings}/weightage-settings`,
      contextSettings: `/${MODULE.context}/${COMMON_KEYWORDS.settings}/context-settings`,
    },
  },
  settings: {
    base: `/${MODULE.settings}`,
    billing: `/${MODULE.settings}/billing`,
    users: `/${MODULE.settings}/users`,
    permissions: `/${MODULE.settings}/permissions`,
  },
  notifications: {
    base: `/${MODULE.notifications}`,
  },
  myAccount: {
    base: `/${MODULE.myAccount}`,
  },
  strategyBoard: {
    base: `/${MODULE.strategyBoard}`,
    creatingTests: `/${MODULE.strategyBoard}/creatingTests`,
    testVersions: `/${MODULE.strategyBoard}/customize/:${COMMON_KEYWORDS.productId}`,
    dashboard: `/${MODULE.dashboard}/`,
    scanCatalog: `/${MODULE.strategyBoard}/welcome`,
    selectProduct: `/${MODULE.strategyBoard}/products`,
    scanCatalogProduct: `/${MODULE.strategyBoard}/recommendations`,
  },
} as const;
