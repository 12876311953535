import { lazy } from'react';
const pageComponentToDefault = (module: any) => ({default: module.PageComponent});
export const ROUTES = [{
  path: "/404", 
  Component: lazy(() => import("./404/+page").then(pageComponentToDefault))
},{
  path: "/contexts", 
  Component: lazy(() => import("./contexts/+page").then(pageComponentToDefault))
},{
  path: "/contexts/settings", 
  Component: lazy(() => import("./contexts/settings/+page").then(pageComponentToDefault))
},{
  path: "/contexts/settings/context-settings", 
  Component: lazy(() => import("./contexts/settings/context-settings/+page").then(pageComponentToDefault))
},{
  path: "/contexts/settings/weightage-settings", 
  Component: lazy(() => import("./contexts/settings/weightage-settings/+page").then(pageComponentToDefault))
},{
  path: "/contexts/utm", 
  Component: lazy(() => import("./contexts/utm/+page").then(pageComponentToDefault))
},{
  path: "/dashboard", 
  Component: lazy(() => import("./dashboard/+page").then(pageComponentToDefault))
},{
  path: "/digital-assets", 
  Component: lazy(() => import("./digital-assets/+page").then(pageComponentToDefault))
},{
  path: "/forbidden", 
  Component: lazy(() => import("./forbidden/+page").then(pageComponentToDefault))
},{
  path: "/my-account", 
  Component: lazy(() => import("./my-account/+page").then(pageComponentToDefault))
},{
  path: "/notifications", 
  Component: lazy(() => import("./notifications/+page").then(pageComponentToDefault))
},{
  path: "/pages", 
  Component: lazy(() => import("./pages/+page").then(pageComponentToDefault))
},{
  path: "/products", 
  Component: lazy(() => import("./products/+page").then(pageComponentToDefault))
},{
  path: "/products/versions/bulk-create", 
  Component: lazy(() => import("./products/versions/bulk-create/+page").then(pageComponentToDefault))
},{
  path: "/products/versions/bulk-edit", 
  Component: lazy(() => import("./products/versions/bulk-edit/+page").then(pageComponentToDefault))
},{
  path: "/reporting", 
  Component: lazy(() => import("./reporting/+page").then(pageComponentToDefault))
},{
  path: "/rich-contents", 
  Component: lazy(() => import("./rich-contents/+page").then(pageComponentToDefault))
},{
  path: "/settings", 
  Component: lazy(() => import("./settings/+page").then(pageComponentToDefault))
},{
  path: "/settings/billing", 
  Component: lazy(() => import("./settings/billing/+page").then(pageComponentToDefault))
},{
  path: "/settings/permissions", 
  Component: lazy(() => import("./settings/permissions/+page").then(pageComponentToDefault))
},{
  path: "/settings/users", 
  Component: lazy(() => import("./settings/users/+page").then(pageComponentToDefault))
},{
  path: "/start-guide", 
  Component: lazy(() => import("./start-guide/+page").then(pageComponentToDefault))
},{
  path: "/test-flow", 
  Component: lazy(() => import("./test-flow/+page").then(pageComponentToDefault))
},{
  path: "/contexts/:id/", 
  Component: lazy(() => import("./contexts/[id]/+page").then(pageComponentToDefault))
},{
  path: "/digital-assets/:id/", 
  Component: lazy(() => import("./digital-assets/[id]/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/", 
  Component: lazy(() => import("./pages/[pageId]/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/settings", 
  Component: lazy(() => import("./pages/[pageId]/settings/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/settings/contextualize-version", 
  Component: lazy(() => import("./pages/[pageId]/settings/contextualize-version/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/settings/test-version", 
  Component: lazy(() => import("./pages/[pageId]/settings/test-version/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/settings/version-setting", 
  Component: lazy(() => import("./pages/[pageId]/settings/version-setting/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/versions/create", 
  Component: lazy(() => import("./pages/[pageId]/versions/create/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/", 
  Component: lazy(() => import("./products/[id]/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/settings", 
  Component: lazy(() => import("./products/[id]/settings/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/settings/contextualize-version", 
  Component: lazy(() => import("./products/[id]/settings/contextualize-version/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/settings/test-version", 
  Component: lazy(() => import("./products/[id]/settings/test-version/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/settings/variants", 
  Component: lazy(() => import("./products/[id]/settings/variants/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/settings/version-setting", 
  Component: lazy(() => import("./products/[id]/settings/version-setting/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/versions/create", 
  Component: lazy(() => import("./products/[id]/versions/create/+page").then(pageComponentToDefault))
},{
  path: "/reporting/groups/:experimentId/", 
  Component: lazy(() => import("./reporting/groups/[experimentId]/+page").then(pageComponentToDefault))
},{
  path: "/rich-contents/:id/", 
  Component: lazy(() => import("./rich-contents/[id]/+page").then(pageComponentToDefault))
},{
  path: "/rich-contents/:id/template", 
  Component: lazy(() => import("./rich-contents/[id]/template/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/versions/:versionId/edit", 
  Component: lazy(() => import("./pages/[pageId]/versions/[versionId]/edit/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/versions/:versionId/overview", 
  Component: lazy(() => import("./pages/[pageId]/versions/[versionId]/overview/+page").then(pageComponentToDefault))
},{
  path: "/pages/:pageId/versions/:versionId/performance", 
  Component: lazy(() => import("./pages/[pageId]/versions/[versionId]/performance/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/versions/:versionId/edit", 
  Component: lazy(() => import("./products/[id]/versions/[versionId]/edit/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/versions/:versionId/overview", 
  Component: lazy(() => import("./products/[id]/versions/[versionId]/overview/+page").then(pageComponentToDefault))
},{
  path: "/products/:id/versions/:versionId/performance", 
  Component: lazy(() => import("./products/[id]/versions/[versionId]/performance/+page").then(pageComponentToDefault))
}];