import { PrimaryButtonVariant } from '@components/PrimaryButton/PrimaryButton';
import { AnyAttributeValue } from '@models/product';
import { TemplateAttribute } from '@apis/template';
import { registerSideEffects } from '@redux/sideEffects';
import { AppThunk } from '@redux/store';
import { createSlice } from '@reduxjs/toolkit';

type BaseModal = {
  type: string;
  heading: string;
  supportingText: string;
};

// TODO Remove
/** @deprecated */
type ModalA = BaseModal & {
  type: 'TypeA';
  icon?: JSX.Element;
  children?: null | JSX.Element | JSX.Element[];
};

/** @deprecated */
type ModalB = BaseModal & {
  type: 'typeB';
  icon?: JSX.Element;
  buttons: {
    text: string;
    variant: PrimaryButtonVariant;
    method: () => void;
  }[];
  className?: string;
  danger: boolean;
  children?: null | JSX.Element | JSX.Element[];
};

/**
 * ! IMPORTANT ! Only use if existing types don't work and use-case is too unique to create new type
 *
 * Allows you to create fully unique modals.
 */
type CustomModal = {
  type: 'custom';
  children: JSX.Element | JSX.Element[];
};

type ConfirmModal = {
  type: 'ConfirmModal';
  icon: JSX.Element | 'danger';
  heading: string;
  description: string;
  confirmLabel: string;
  /** @default "Cancel" */
  cancelLabel?: string;
  onConfirm: () => unknown;
};

type TextInputModal = {
  type: 'TextInputModal';
  icon: JSX.Element;
  submitLabel: string;
  placeholder: string;
  onSubmit: (value: string) => unknown;
  maxLength?: number;
};

type UsageModal = {
  type: 'UsageModal';
  heading: string;
  description: string;
  usages: Array<OrPromise<{ label: string; link: string }>>;
};

type VersionPreviewModal = {
  type: 'VersionPreviewModal';
};

type EditorModal = {
  type: 'EditorModal';
  attribute: TemplateAttribute;
  initialValue: AnyAttributeValue;
  heading: React.ReactNode;
  reference?: AnyAttributeValue;
  onSubmit: (value: AnyAttributeValue) => unknown;
};

type UpgradeRequiredModal = {
  type: 'UpgradeRequiredModal';
};

export type Modal =
  | ModalA
  | ModalB
  | CustomModal
  | ConfirmModal
  | TextInputModal
  | UsageModal
  | VersionPreviewModal
  | EditorModal
  | UpgradeRequiredModal;

type ModalState = {
  /** The modal that is currently open */
  current: Modal | null;
};

const initialState: DeepReadonly<ModalState> = {
  current: null,
};

const { actions, reducer } = createSlice({
  name: 'modal',
  initialState,
  // @ts-expect-error ts(2589)
  reducers: {
    open: (state, { payload }: { payload: Modal }) => {
      state.current = payload;
    },
    close: (state) => {
      state.current = null;
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RModal = Object.assign(actions, thunks);

export default reducer;
