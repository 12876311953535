import { DateRangePicker } from '@components/DateRangePicker/DateRangePicker';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import './PageHeading.scss';

export const PageHeading: React.FC<{
  title: string;
  className?: string;
  dateRangePicker?: boolean;
}> = ({ title, children, className = '', dateRangePicker }) => (
  <div className={`PageHeading ${className}`}>
    <span className="PageHeading-title">
      {capitalizeEveryFirstLetter(title)}
    </span>
    <div className="PageHeading-children">
      {children}
      {dateRangePicker && (
        <DateRangePicker
          className="PageHeading-datepicker"
          module="Dashboard"
        />
      )}
    </div>
  </div>
);
