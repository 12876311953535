import { Version } from '@apis/versions';
import { RVersion } from '@redux/slices/version';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Returns the current version from the redux state and loads the version if `versionId` is provided */
export const useVersion = (versionId?: string): Version | null => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (versionId) dispatch(RVersion.loadVersion(versionId));
  }, [dispatch, versionId]);

  const { currentItem: version } = useSelector((state) => state.versions);

  return version;
};
