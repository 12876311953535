import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export const RangePicker: React.FC<{
  startDate: Date;
  endDate: Date | null;
  setRange: (range: { start: Date; end: Date | null }) => void;
  minDate?: Date;
}> = ({ startDate, endDate, minDate, setRange }) => {
  /**
   * Triggers on change of date
   * If @param startDate is already selected, set @param endDate
   * Otherwise nullify @param endDate and set @param startDate
   */
  const onDateChange = (dates: [Date | null, Date | null]) => {
    if (dates[1]) setRange({ start: dates[0]!, end: dates[1] });
    else setRange({ start: dates[0]!, end: null });
  };

  /** current UTC date */
  const currentDate = moment().utc().toDate();

  return (
    <div className="DateRangePicker-picker--range">
      <DatePicker
        selected={startDate}
        focusSelectedMonth={false}
        startDate={startDate}
        endDate={endDate}
        monthsShown={2}
        minDate={minDate}
        selectsRange
        inline
        disabledKeyboardNavigation
        onChange={onDateChange}
        maxDate={currentDate}
        calendarClassName="border-0"
        useWeekdaysShort
      />
    </div>
  );
};
