import { TestStrategy } from '@apis/aiService';
import {
  TestTargets,
  colorsByTestLevel,
} from '@components/TestTargets/TestTargets';

import './style.scss';

export const TestCard = ({
  test,
  isSelected,
  testLevel,
  setSelected,
}: {
  test: TestStrategy;
  isSelected: boolean;
  testLevel: TestStrategy['level'];
  setSelected: (test: TestStrategy) => any;
}) => {
  const colorSet = colorsByTestLevel[testLevel];

  const cssDefinitions = {
    '--test-card-primary-color': colorSet.primary,
    '--test-card-secondary-color': colorSet.secondary,
    '--test-card-background-color': colorSet.background,
  } as React.CSSProperties;

  return (
    <label
      className={'test-card' + (isSelected ? ' active' : '')}
      htmlFor={'test-card-' + test.id}
      style={cssDefinitions}
    >
      <div className="test-card-inner">
        <TestTargets test={test} />
        <h4>{test.title}</h4>

        <div className="test-card-hover">{test.description}</div>
        <input
          id={'test-card-' + test.id}
          className="select"
          name="test-card"
          type="radio"
          checked={isSelected}
          onChange={() => setSelected(test)}
        />
      </div>
    </label>
  );
};
