import {
  ListablePayload,
  ListableState,
  ListConfiguration,
} from '@models/filter';
import { HeuristicRecord } from '@models/index';
import { AttributeValueMap } from '@models/product';
import { createApi } from '.';

export namespace PagesApi {
  export namespace List {
    export type State = ListableState<Page, typeof pagesListConfig>;
    export type Query = ListablePayload<typeof pagesListConfig>;
  }
}

export const pagesListConfig = {
  sort: [
    { sortBy: 'relevanceScore', sortOrder: 'desc' },
    { sortBy: 'title', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'status',
      multiple: false,
      hidden: true,
      options: ['draft', 'live', 'archived', 'deleted'],
    },
  ],
} as const satisfies ListConfiguration;

const api = createApi<
  {
    title: {
      type: 'string';
      description: 'The page name (e.g., "Homepage")';
    };
    pathname: {
      type: 'string';
      pattern: '^/.*[^/]$';
      description: 'The URL path without trailing slash (e.g., "/" or "/collections/all")';
    };
    type: {
      type: 'string';
      enum: ['home', 'collection', 'blog', 'landing', 'other'];
      description: 'The type of page';
    };
    thumbnail: {
      type: ['string', 'null'];
      format: 'uri';
      nullable: true;
      description: 'URL to an image representing the page such as a screenshot';
    };
    status: {
      type: 'string';
      enum: ['draft', 'live', 'archived', 'deleted'];
      description: 'The status of the page';
    };
    relevanceScore: {
      type: 'number';
      description: 'An internal scoring of how relevant a page is to work on';
      default: 0;
    };
    experiences: {
      type: 'object';
      required: ['total', 'live', 'draft', 'disabled'];
      properties: {
        total: {
          type: 'integer';
          description: 'Total number of experiences';
          default: 0;
          minimum: 0;
        };
        live: {
          type: 'integer';
          description: 'Number of live experiences';
          default: 0;
          minimum: 0;
        };
        draft: {
          type: 'integer';
          description: 'Number of draft experiences';
          default: 0;
          minimum: 0;
        };
        disabled: {
          type: 'integer';
          description: 'Number of disabled experiences';
          default: 0;
          minimum: 0;
        };
      };
      description: 'Details on how many experiences in each category exist';
      default: {
        total: 0;
        live: 0;
        draft: 0;
        disabled: 0;
      };
    };
  },
  ['title', 'pathname', 'thumbnail', 'type']
>('/pages');

export type Page = typeof api.types.response;
export type PageWithAttributes = Page & { attributes: AttributeValueMap };

export const listPages = api.list;

export const getPage = api.get;

export const getPageContent = (id: string) =>
  api.request<HeuristicRecord<{ attributes: AttributeValueMap }>>(
    `/${id}/content`,
  );
