import { Product } from '@apis/products';
import fallbackImage from '@assets/illustrations/57_NoImage.svg';

import './style.scss';

export const RecommendedProductsView = ({
  recommendedProducts,
  selectedProduct,
  setSelectedProduct,
}: {
  recommendedProducts: Product[] | undefined;
  selectedProduct: Product | undefined;
  setSelectedProduct: (product: Product) => any;
}) => {
  return (
    <div className="recommended-product-list">
      {recommendedProducts
        ? recommendedProducts.map((product, i) => (
            <label
              className="product-card"
              htmlFor={'recommended-product-' + i}
              key={i}
            >
              <div>
                <img
                  src={product.thumbnail || fallbackImage}
                  alt={product.title + ' Thumbnail'}
                />
                <p>{product.taxonomies.join(' / ') || '\u00A0'}</p>
                <h4>{product.title}</h4>
                <p>${Number(product.price).toLocaleString()}</p>
                <input
                  type="radio"
                  name="recommended-product"
                  id={'recommended-product-' + i}
                  checked={selectedProduct?.id === product.id}
                  onChange={() => setSelectedProduct(product)}
                />
              </div>
            </label>
          ))
        : [0, 1, 2].map((i) => (
            <div className="product-card" key={i}>
              <div className="loading"></div>
            </div>
          ))}
    </div>
  );
};
