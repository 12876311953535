import { DropdownIcon } from '@components/Icons/DropdownIcon';
import { UpdatedCalenderIcon } from '@components/Icons/UpdatedCalenderIcon';
import { colors } from '@constants/colors';
import { RTenant } from '@redux/slices/tenant';
import { formatDate, getDateInTimezone, setDate } from '@utils/dateFormat';
import { Mixpanel } from '@utils/mixpanel';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { ActionButtons } from './ActionButtons';
import { PredefinedDates } from './PredefinedDates';
import { RangePicker } from './RangePicker';

import './DateRangePicker.scss';

export type DateRange = {
  startDateTime: number;
  endDateTime: number;
  startDate: Date;
  endDate: Date;
  selectedPredefined: PredefinedDateOption | null;
};

const PREDEFINED_DATE_OPTIONS = [
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 14 days',
  'Last 30 days',
  'Since Launch',
] as const;

export type PredefinedDateOption = (typeof PREDEFINED_DATE_OPTIONS)[number];

export const DateRangePicker: React.FC<{
  module: string;
  className?: string;
}> = ({ module, className }) => {
  const dispatch = useDispatch();
  const defaultRange = useSelector((state) => state.tenant.defaultRange);
  const customDate = useSelector((state) => state.tenant.customDate);
  const analyticsPeriod = useSelector((state) => state.tenant.analyticsPeriod);
  const selectedDate = useSelector((state) => state.tenant.selectedDate);
  const onboardingDate =
    useSelector((state) => state.tenant.onboardingDate) || Date.now();

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen((open) => !open), []);

  const [startDate, setStartDate] = useState<Date>(() =>
    setDate('Last 30 days').toDate(),
  );
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());
  const [displayRange, setDisplayRange] = useState<string>('');
  const [selectedPredefined, setSelectedPredefined] =
    useState<PredefinedDateOption | null>(null);

  // Initialize from redux state when component mounts
  useEffect(() => {
    if (defaultRange !== null && !customDate) {
      // Use default range
      setDisplayRange(defaultRange);
      setSelectedPredefined(defaultRange as PredefinedDateOption);
    } else if (customDate && selectedDate) {
      if (!selectedDate.selectedPredefined) {
        // Custom date range
        const formattedStartDate = formatDate(
          selectedDate.startDate,
          'format2',
        );
        const formattedEndDate = formatDate(selectedDate.endDate, 'format2');

        if (
          formattedStartDate !== formattedEndDate &&
          selectedDate.endDate != null
        ) {
          setDisplayRange(`${formattedStartDate} - ${formattedEndDate}`);
        } else {
          setDisplayRange(formattedStartDate);
        }

        setStartDate(moment(selectedDate.startDate).toDate());
        setEndDate(moment(selectedDate.endDate).toDate());
      } else {
        // Predefined date from selected date
        setDisplayRange(selectedDate.selectedPredefined);
        setSelectedPredefined(
          selectedDate.selectedPredefined as PredefinedDateOption,
        );
      }
    }
  }, [defaultRange, customDate, selectedDate]);

  // Update dates when predefined option changes
  useEffect(() => {
    if (selectedPredefined) {
      // Special case for 'Since Launch'
      if (selectedPredefined === 'Since Launch') {
        if (analyticsPeriod?.start) {
          setStartDate(getDateInTimezone(analyticsPeriod.start.toString()));
          if (analyticsPeriod?.end)
            setEndDate(getDateInTimezone(analyticsPeriod.end.toString()));
          else setEndDate(moment().toDate());
        } else {
          // Fallback to 30 days if no analytics period is available
          console.warn(
            'No analytics period data for Since Launch, falling back to 30 days',
          );
          setStartDate(moment().subtract(30, 'days').toDate());
          setEndDate(moment().toDate());
        }
      } else {
        // Handle other predefined options
        setStartDate(setDate(selectedPredefined).toDate());

        // Special case for 'Yesterday'
        if (selectedPredefined === 'Yesterday')
          setEndDate(moment().subtract(1, 'd').toDate());
        else setEndDate(moment().toDate());
      }
    }
  }, [selectedPredefined, analyticsPeriod]);

  const handleRangeChange = (
    value: PredefinedDateOption | { start: Date; end: Date | null },
  ) => {
    // Handle predefined date selection
    if (typeof value === 'string') {
      setSelectedPredefined(value);
    }
    // Handle date selection
    else {
      setStartDate(value.start);
      setEndDate(value.end);

      setSelectedPredefined(null);
    }

    Mixpanel.track('Calender', {
      eventName: module,
      kpi: 'User Learnings',
      propertyType: 'event_property',
      dateRange: value,
    });
  };

  const applyDateRange = () => {
    /** If no endDate selected, a single day is selected */
    const safeEndDate = endDate ?? startDate;
    let startDateTime: number;
    let endDateTime: number;

    if (selectedPredefined) {
      // Handle predefined date ranges
      setDisplayRange(selectedPredefined);

      if (selectedPredefined === 'Since Launch') {
        if (analyticsPeriod?.start) {
          startDateTime = moment(analyticsPeriod.start.toString()).valueOf();
          endDateTime = analyticsPeriod?.end
            ? moment(analyticsPeriod.end.toString()).valueOf()
            : moment().valueOf();
        } else {
          // If analytics period isn't available, fallback to a reasonable default (30 days)
          console.warn(
            'No analytics period data for Since Launch, falling back to 30 days',
          );
          startDateTime = moment().subtract(30, 'days').valueOf();
          endDateTime = moment().valueOf();
        }
      } else if (selectedPredefined === 'Yesterday') {
        startDateTime = setDate(selectedPredefined).valueOf();
        endDateTime = moment().subtract(1, 'd').endOf('day').valueOf();
      } else {
        startDateTime = setDate(selectedPredefined).valueOf();
        endDateTime = moment().valueOf();
      }
    } else {
      // Handle custom date range
      const formattedStartDate = formatDate(startDate, 'format2');
      const formattedEndDate = formatDate(safeEndDate, 'format2');

      if (startDate.valueOf() === safeEndDate.valueOf()) {
        setDisplayRange(formattedStartDate);
      } else setDisplayRange(`${formattedStartDate} - ${formattedEndDate}`);

      startDateTime = moment(startDate).valueOf();
      endDateTime = moment(endDate).valueOf();

      // Handle single date selection
      if (isNaN(endDateTime)) endDateTime = startDateTime;
    }

    // Update Redux state
    const dateRange: DateRange = {
      startDateTime,
      endDateTime,
      startDate,
      endDate: safeEndDate,
      selectedPredefined,
    };

    dispatch(RTenant.SET_SELECTED_DATE(dateRange));
    toggleIsOpen();

    Mixpanel.track('Calender', {
      eventName: module,
      kpi: 'User Learnings',
      propertyType: 'event_property',
      startDate: startDateTime,
      endDate: endDateTime,
    });
  };

  return (
    <div className={`DateRangePicker ${className || ''}`}>
      <div className="DateRangePicker-input">
        <Button
          id="date-picker-calendar"
          background={colors.white}
          padding="0px 6px 0px 3px"
          action={toggleIsOpen}
        >
          <UpdatedCalenderIcon />
        </Button>
        <Text
          color={colors.oxfordBlue}
          size={12}
          weight={400}
          block={false}
          id="date-range-text"
        >
          {displayRange}
        </Text>
        <Button
          id="date-picker-dropdown-btn"
          background={colors.white}
          padding="0px 0px 0px 12px"
          action={toggleIsOpen}
        >
          <DropdownIcon />
        </Button>
      </div>
      {isOpen && (
        <div className="DateRangePicker-picker">
          <div className="DateRangePicker-picker-main">
            <PredefinedDates
              selectedPredefined={selectedPredefined}
              setRange={handleRangeChange}
              predefinedOptions={PREDEFINED_DATE_OPTIONS}
            />
            <RangePicker
              startDate={startDate}
              endDate={endDate}
              setRange={handleRangeChange}
              minDate={onboardingDate ? new Date(onboardingDate) : new Date()}
            />
          </div>
          <ActionButtons
            changeRange={applyDateRange}
            clearRange={toggleIsOpen}
          />
        </div>
      )}
    </div>
  );
};
