import { combineReducers } from 'redux';
import AssetSlice from './asset';
import ContextSlice from './context';
import CurrentUserSlice from './currentUser';
import ExperimentSlice from './experiment';
import KeyCloakSlice from './keyCloak';
import MetadataSlice from './metadata';
import ModalSlice from './modal';
import PagesSlice from './pages';
import ProductSlice from './product';
import QuickStartGuideSlice from './quickStartGuide';
import RichContentSlice from './richContent';
import SidebarSlice from './sidebar';
import SpinnerSlice from './spinner';
import TemplateSlice from './template';
import TenantSlice from './tenant';
import UtmSlice from './utm';
import VersionSlice from './version';

export const rootReducer = combineReducers({
  assets: AssetSlice,
  contexts: ContextSlice,
  currentUser: CurrentUserSlice,
  experiments: ExperimentSlice,
  keyCloak: KeyCloakSlice,
  metadata: MetadataSlice,
  modals: ModalSlice,
  pages: PagesSlice,
  products: ProductSlice,
  quickStartGuide: QuickStartGuideSlice,
  richContent: RichContentSlice,
  sidebar: SidebarSlice,
  spinner: SpinnerSlice,
  templates: TemplateSlice,
  tenant: TenantSlice,
  utms: UtmSlice,
  versions: VersionSlice,
});
