import { AttributeTypes, Template, TemplateAttribute } from '@apis/template';
import { toast } from '@components/ToastNotification/ToastManager';
import { HTML_TAG_MATCHER, SPACE_MATCHER } from '@constants/regex';
import { AnyAttributeValue, AttributeValueMap } from '@models/product';
import DOMPurify from 'dompurify';

export const getHTMLLength = (str: string): number => {
  const sanitized = DOMPurify.sanitize(str);
  const div = document.createElement('div');
  div.innerHTML = sanitized;
  return div.innerText.length;
};

export const checkIsAttributeInputValid = (
  attributeValue: string | string[],
  templateAttribute: TemplateAttribute,
  showError = true,
): boolean => {
  if (!templateAttribute.validator) return true;
  const { validator } = templateAttribute;

  const length =
    typeof attributeValue === 'string'
      ? attributeValue.replace(SPACE_MATCHER, ' ').replace(HTML_TAG_MATCHER, '')
          .length
      : attributeValue.length;

  const validMin = validator.min === undefined ? true : length >= validator.min;
  const validMax = validator.max === undefined ? true : length <= validator.max;

  if (validMin && validMax) return true;

  if (showError) {
    const issue = !validMax
      ? 'less than ' + (validator.max! + 1)
      : 'more than ' + (validator.min! - 1);
    const type =
      templateAttribute.type === 'assets'
        ? 'assets'
        : templateAttribute.type === 'richContent'
        ? 'rich-contents'
        : 'characters';
    const message = `Invalid attribute "${templateAttribute.displayName}" — must have ${issue} ${type}`;
    toast.show({ error: true, message });
  }
  return false;
};

/** @returns any invalid attributes found */
export const validateAttributes = (
  content: AttributeValueMap,
  template: Template,
  validateAll = false,
): string[] => {
  const invalid: string[] = [];
  const attributes = { ...content };

  if (validateAll) {
    for (const { name, type } of template.attributes) {
      const fallback = ['html', 'text'].includes(type) ? '' : [];
      attributes[name] = attributes[name] || fallback;
    }
  }

  for (const attribute in attributes) {
    const value = attributes[attribute];
    const constraints = template.attributes.find(
      ({ name }) => name === attribute,
    );
    if (!constraints) invalid.push(attribute);
    else {
      const { validator, type, displayName } = constraints;
      const expected: { [type in AttributeTypes]: string } = {
        text: 'string',
        html: 'string',
        assets: 'object',
        richContent: 'object',
      };
      if (typeof value !== expected[type]) invalid.push(displayName);
      else if (validator) {
        const len =
          type !== 'html' ? value.length : getHTMLLength(value as string);
        if (validator.min && len < validator.min) invalid.push(displayName);
        else if (validator.max && len > validator.max)
          invalid.push(displayName);
      }
    }
  }
  return invalid;
};

/**
 * Checks if the value can be used for that attribute
 * @returns Assertion that value is `string & string[]`, however only applicable for the attribute provided
 */
export const isValidAttributeValue = (
  attribute: TemplateAttribute,
  value: DeepReadonly<AnyAttributeValue>,
): value is string & string[] => {
  /** If we should expect a string */
  const expectedString = ['text', 'html'].includes(attribute.type);
  const isString = typeof value === 'string';
  return expectedString === isString;
};

export class AttributeValueError extends Error {
  constructor(
    public attribute: TemplateAttribute,
    public value: DeepReadonly<AnyAttributeValue>,
  ) {
    super(
      `Value of type ${
        typeof value === 'string' ? 'string' : 'string[]'
      } cannot be assigned to attribute ${attribute.name} which is of type ${
        attribute.type
      }`,
    );
  }
}
