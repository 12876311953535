import { ListConfiguration } from '@models/filter';
import { AttributeValueMap } from '@models/product';
import { Mixpanel } from '@utils/mixpanel';
import { cleanVersion } from '@utils/version';
import { createApi } from '.';

export const versionListConfig = {
  sort: [
    { sortBy: 'title', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'createdAt', sortOrder: 'desc' },
    { sortBy: 'createdAt', sortOrder: 'asc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'type',
      multiple: false,
      hidden: true,
      options: ['test', 'personalize'],
    },
    {
      name: 'status',
      multiple: true,
      hidden: true,
      options: [
        'draft',
        'in-progress',
        'published',
        'expired',
        'awaiting-publication',
        'archived',
        'paused',
      ] as Array<Version['status']>,
    },
    {
      name: 'parent.type',
      hidden: true,
      multiple: false,
      options: [] as string[],
    },
    {
      name: 'parent.id',
      hidden: true,
      multiple: false,
      options: [] as string[],
    },
  ],
} as const satisfies ListConfiguration;

const api = createApi<
  {
    title: { type: 'string'; description: 'The name of the experience' };
    parent: {
      type: 'object';
      properties: {
        id: { type: 'string'; description: 'The ID of the parent' };
        type: {
          type: 'string';
          enum: ['product', 'page'];
          description: 'The type of parent';
        };
      };
      additionalProperties: false;
      required: ['id', 'type'];
      description: 'The parent of this experience';
    };
    type: {
      type: 'string';
      enum: ['test', 'personalize'];
      description: 'If this experience is used to test or personalize';
    };
    trafficPercentage: {
      type: ['number', 'null'];
      minimum: 0;
      maximum: 100;
      description: 'The amount of traffic this experience should receive';
    };
    thumbnail: {
      type: ['string', 'null'];
      format: 'uri';
      description: 'The URL to an image symbolizing the experience';
      default: null;
    };
    status: {
      type: 'string';
      enum: [
        'draft',
        'in-progress',
        'published',
        'expired',
        'awaiting-publication',
        'archived',
        'paused',
      ];
      description: 'The status of the experience';
      readOnly: true;
      default: 'draft';
    };
    experimentIds: {
      type: 'array';
      items: { type: 'string' };
      description: 'The experiments tied to the experience';
      default: [];
    };
    contextIds: {
      type: 'array';
      items: { type: 'string' };
      description: 'The contexts tied to the experience';
      default: [];
    };
    attributes: {
      type: 'object';
      additionalProperties: {
        type: ['array', 'string'];
        items: { type: 'string' };
      };
      description: 'The attributes of the experience that it changes from the base';
    };
    variantOverrides: {
      type: 'object';
      additionalProperties: {
        type: 'object';
        additionalProperties: {
          type: ['string', 'array'];
          items: { type: 'string' };
        };
      };
      description: 'Variant-specific overrides for the attributes';
    };
    referenceVersion: {
      type: ['string', 'null'];
      description: 'The version to be used as a reference';
      default: null;
    };
    publishedAt: {
      type: ['number', 'null'];
      description: 'The date the experience was published (in milliseconds since epoch)';
      readOnly: true;
      default: null;
    };
    expiresAt: {
      type: ['number', 'null'];
      description: 'A date when the experience expires (in milliseconds since epoch)';
      readOnly: true;
      default: null;
    };
  },
  [
    'title',
    'parent',
    'type',
    'trafficPercentage',
    'attributes',
    'variantOverrides',
  ]
>('/experiences');

export type Version = typeof api.types.response;

export namespace Version {
  export type Post = typeof api.types.post;
  export type Patch = typeof api.types.patch;
}

export const listVersions = api.list;
/** Crates a new version, automatically cleaning up the body where via {@link cleanVersion} */
export const createVersion = (body: Version.Post): Promise<Version | Error> =>
  api.post(cleanVersion(body));
export const fetchVersion = api.get;
export const patchVersion = api.patch;
export const deleteVersion = api.delete;

export const publishVersion = (id: string) =>
  api.request<Version>(`/${id}/publish`, { method: 'POST' });

export const unpublishVersion = (id: string) =>
  api.request<Version>(`/${id}/unpublish`, { method: 'POST' });

/** Publish a temporary preview version to the RACE cache */
export const previewVersion = (
  id: string,
  attributes?: AttributeValueMap,
  variantOverrides?: Record<string, AttributeValueMap>,
) => {
  const withOverrides = attributes || variantOverrides;

  Mixpanel.track('version_operation', {
    eventName: 'Generate version preview',
    kpi: 'Usage Grows',
    propertyType: 'event_property',
    versionId: id,
    previewType: withOverrides ? 'wip' : 'current',
  });

  if (!withOverrides) return api.request<Version>(`/${id}/preview`);

  return api.request<Version>(`/${id}/preview`, {
    method: 'POST',
    body: { attributes, variantOverrides },
  });
};

export const listVersionsByParent = (
  type: 'product' | 'page',
  id: string,
  params: Parameters<typeof api.list>[0] = {},
) => {
  const cases = [`EQ(parent.type,${type})`, `EQ(parent.id,${id})`];
  if (params.filter) cases.push(params.filter);
  params.filter = `AND(${cases.join(',')})`;
  return api.list(params);
};

/**
 * Checks if there's a version with the provided contexts
 *
 * @returns Any duplicated contextIds by the version that has the contextId
 */
export const versionWithContextsExists = async (
  parent: Version['parent'],
  contextIds: string[],
  versionId: string | null,
): Promise<false | { [versionId: string]: string[] } | Error> => {
  const contextIdList = contextIds.join(',');
  const filters = [
    `EQ(parent.type,${parent.type})`,
    `EQ(parent.id,${parent.id})`,
    `IN(contextIds,${contextIdList})`,
  ];
  if (versionId) filters.push(`NE(id,${versionId})`);
  const result = await api.list({
    filter: `AND(${filters.join(',')})`,
    limit: 1,
  });
  if (result instanceof Error) return result;
  if (result.items.length === 0) return false;

  const duplicatesByVersion: { [versionId: string]: string[] } = {};
  for (const version of result.items) {
    const duplicates = version.contextIds.filter((id) =>
      contextIds.includes(id),
    );
    duplicatesByVersion[version.id] = duplicates;
  }
  return duplicatesByVersion;
};
