import { TemplateAttribute } from '@apis/template';
import { ReactComponent as HTMLIcon } from '@assets/icons/HTMLIcon.svg';
import { ReactComponent as RCHorseIcon } from '@assets/icons/RCHorse.svg';
import { AssetPreviewModal } from '@components/AssetPreviewModal/AssetPreviewModal';
import {
  ActionButtonProps,
  ConfirmationModal,
} from '@components/ConfirmationModal/ConfirmationModal';
import { DigitalAssetsModal } from '@components/DigitalAssetsModal/DigitalAssetsModal';
import { RichTextEditor } from '@components/EditorBox/EditorBox';
import { UrlIcon } from '@components/Icons/UrlIcon';
import useLegacyModal from '@components/ModalLayout/useModal';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { ReadOnlyModal } from '@components/ReadOnlyModal/ReadOnlyModal';
import { RichContentPreviewModal } from '@components/RichContentPreviewModal/RichContentPreviewModal';
import { TextareaWithoutBorder } from '@components/TextareaWithoutBorder/TextareaWithoutBorder';
import { DigitalAssetBrief } from '@models/digital-asset';
import { AnyAttributeValue } from '@models/product';
import { IRichContent } from '@models/rich-content';
import { RVersion } from '@redux/slices/version';
import { isAssetVideo } from '@utils/digitalAssets';
import { ALL_IMAGES, ALL_VIDEOS } from '@utils/files';
import {
  AttributeValueError,
  getHTMLLength,
  isValidAttributeValue,
} from '@utils/template';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import HTMLReactParser from 'html-react-parser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAssets } from '../../../hooks/useAssets';
import { useBaseVersion } from '../../../hooks/useBaseVersion';
import { Asset } from '../Asset/Asset';
import { DragDropList, modalIds } from './DragDropList/DragDropList';
import { RichContentSelectModal } from './RichContentSelectModal/RichContentSelectModal';

import { Variant } from '@apis/products';
import './AttributeField.scss';

type FieldCreator<T extends AnyAttributeValue> = (
  value: T | undefined,
  onChange: (newValue: T) => any,
  updating: boolean,
  valid?: boolean,
) => React.ReactNode;

const TextField = ({
  value,
  onChange,
  updating,
  valid,
}: {
  value: string | undefined;
  onChange: (value: string) => void;
  updating: boolean;
  valid?: boolean;
}) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    if (!text || (updating && value)) setText(value);
  }, [updating, value, text]);

  return (
    <input
      type="text"
      value={text || ''}
      onChange={(e) => {
        setText(e.target.value);
        onChange(e.target.value);
      }}
      className={valid ? '' : 'invalidInput'}
    />
  );
};

const HTMLField = ({
  value,
  onChange,
  valid,
}: {
  value: string | undefined;
  onChange: (value: string) => void;
  valid?: boolean;
}) => {
  const id = useMemo(() => crypto.randomUUID(), []);
  return (
    <RichTextEditor id={id} value={value} onChange={onChange} valid={valid} />
  );
};

const RichContentField = ({
  richContents = [],
  onChange,
}: {
  richContents: string[];
  onChange: (value: string[]) => void;
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [previewRichContent] = useState<IRichContent>(); // TODO
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const [modalText, setModalText] = useState('');

  const { currentItem: base } = useSelector((s) => s.products || s.pages);

  const modalIds = {
    createRichContentSelect: 'SELECT_RC_MODAL_ID',
    heuristicRichContent: 'HEURISTIC_RC_MODAL',
    htmlRichContent: 'HTML_RC_MODAL',
    urlRichContent: 'URL_RC_MODAL',
    deleteRichContent: 'DELETE_RC_MODAL',
    previewHeuristicRichContent: 'PREVIEW_RC_MODAL',
  };

  const rcDataUrls = {
    HTML: 'data:text/html,',
    URL: 'data:text/uri-list,',
  } as const;

  const RC_CATEGORIES = [
    {
      type: 'Heuristic',
      icon: <RCHorseIcon />,
      onClickShowModal: modalIds.heuristicRichContent, // TODO
      name: t('global.heuristic_commerce'),
    },
    {
      type: 'HTML',
      icon: <HTMLIcon />,
      onClickShowModal: modalIds.htmlRichContent,
      name: t('global.html'),
    },
    {
      type: 'URL',
      icon: <UrlIcon />,
      onClickShowModal: modalIds.urlRichContent, // TODO
      name: t('global.url'),
    },
  ] as const;

  const openModalOnIndex = (modalId: string, index: number) => {
    setSelectedIndex(index);
    if (index === richContents.length && modalId === 'update-modal')
      return openModal(modalIds.createRichContentSelect);

    const [type] = Object.entries(rcDataUrls).find(([_, start]) =>
      richContents[index].startsWith(start),
    ) || ['Heuristic'];

    if (modalId === 'fullscreenModalId' && type === 'Heuristic')
      openModal(modalIds.previewHeuristicRichContent);
    else if (modalId === 'update-modal' || modalId === 'fullscreenModalId') {
      const category = RC_CATEGORIES.find((i) => i.type === type)!;
      if (type !== 'Heuristic') {
        const rawText = richContents[index];
        setModalText(rawText?.substring(rcDataUrls[type].length) || '');
      }
      openModal(category.onClickShowModal);
    } else if (modalId === 'delete-modal')
      openModal(modalIds.deleteRichContent);
  };

  return (
    <>
      <DragDropList
        listClassName="rich-content-list"
        childClassName={() => 'rich-content-item'}
        items={richContents}
        mapItem={(item) => {
          if (item.startsWith(rcDataUrls.HTML)) return <HTMLIcon />;
          if (item.startsWith(rcDataUrls.URL)) return <UrlIcon />;
          return <RCHorseIcon />;
        }}
        onReordered={onChange}
        openModalOnIndex={openModalOnIndex}
        canAdd={true}
        canDelete={true}
      />

      {/** Create RC Modal */}
      <ReadOnlyModal
        showModal={showModalId === modalIds.createRichContentSelect}
        setShowModal={closeModal}
        modalHeader={
          <p className="rich-content-modal__header">
            {capitalizeFirstLetter(t('taskMaster.please_select_from_below'))}
          </p>
        }
        modalBody={
          <>
            <p className="rich-content-modal__instruction">
              {capitalizeFirstLetter(t('taskMaster.rich_content_select_type'))}
            </p>
            <div className="rich-content-modal__options">
              {RC_CATEGORIES.map((category) => (
                <div
                  key={category.onClickShowModal}
                  className="rich-content-modal__icon"
                  onClick={() => {
                    if (category.type !== 'Heuristic') {
                      let initial = rcDataUrls[category.type];
                      if (category.type === 'URL') initial += 'https://';
                      else initial += '<div>Example</div>';
                      setModalText(
                        initial.substring(rcDataUrls[category.type].length),
                      );
                      onChange([...richContents, initial]);
                    }
                    openModal(category.onClickShowModal);
                  }}
                >
                  {category.icon}
                  <p>{category.name}</p>
                </div>
              ))}
            </div>
          </>
        }
      />

      {/** HTML RC Modal */}
      <RichContentSelectModal
        isOpen={showModalId === modalIds.heuristicRichContent}
        closeModal={closeModal}
        onSelectionEnd={(selected) => {
          const clone = [...richContents];
          if (selectedIndex !== clone.length)
            clone.splice(selectedIndex, 1, ...selected);
          else clone.push(...selected);
          onChange(clone);
        }}
        productId={base?.id || ''}
      />

      {/** HTML RC Modal */}
      <ConfirmationModal
        className="html-rich-content-editor-modal"
        modalIcon={<HTMLIcon />}
        showModal={modalIds.htmlRichContent === showModalId}
        setShowModal={closeModal}
        title={capitalizeFirstLetter(t('taskMaster.please_add_html_here'))}
        statement={capitalizeFirstLetter(
          t('taskMaster.added_html_instruction'),
        )}
        buttons={[
          {
            text: capitalizeFirstLetter(t('global.cancel')),
            color: 'gray',
            type: 'cancel',
          },
          {
            text: capitalizeFirstLetter(t('global.done')),
            color: 'green',
            type: 'submit',
          },
        ]}
        clickHandler={() => {
          const updated = [...richContents];
          updated[selectedIndex] = rcDataUrls.HTML + modalText;
          onChange(updated);
          closeModal();
        }}
        cancelHandler={closeModal}
        customComponent={
          <TextareaWithoutBorder onChange={setModalText} value={modalText} />
        }
      />

      {/** URL RC Modal */}
      <ConfirmationModal
        className="url-rich-content-editor-modal"
        modalIcon={<UrlIcon />}
        showModal={modalIds.urlRichContent === showModalId}
        setShowModal={closeModal}
        title={capitalizeFirstLetter(t('taskMaster.please_add_the_URL_here'))}
        statement={HTMLReactParser(
          `${capitalizeFirstLetter(
            t('taskMaster.incase_of_more_than_one_URL'),
          )}<p>(${capitalizeFirstLetter(
            t('taskMaster.added_url_instruction'),
          )})</p>`,
        )}
        buttons={[
          {
            text: capitalizeFirstLetter(t('global.cancel')),
            color: 'gray',
            type: 'cancel',
          },
          {
            text: capitalizeFirstLetter(t('global.done')),
            color: 'green',
            type: 'submit',
          },
        ]}
        clickHandler={() => {
          const updated = [...richContents];
          updated[selectedIndex] = rcDataUrls.URL + modalText;
          onChange(updated);
          closeModal();
        }}
        cancelHandler={closeModal}
        customComponent={
          <TextareaWithoutBorder onChange={setModalText} value={modalText} />
        }
      />

      {/** Delete RC Modal */}
      <ConfirmationModal
        showModal={modalIds.deleteRichContent === showModalId}
        setShowModal={closeModal}
        title={capitalizeEveryFirstLetter(
          `${t('global.remove')} ${t('rich_content.title')}`,
        )}
        statement={capitalizeFirstLetter(
          `${t('taskMaster.removeConfirmationSingleTwo')}`,
        )}
        buttons={[
          {
            text: capitalizeFirstLetter(t('global.no_cancel')),
            color: 'gray',
            type: 'cancel',
          },
          {
            text: capitalizeFirstLetter(t('global.yes_remove')),
            color: 'red',
            type: 'submit',
          },
        ]}
        clickHandler={() => {
          const clone = [...richContents];
          clone.splice(selectedIndex, 1);
          onChange(clone);
          closeModal();
        }}
        isDanger
      />

      {/** View Rich Content */}
      {previewRichContent && (
        <RichContentPreviewModal
          showModal={showModalId === modalIds.previewHeuristicRichContent}
          closeModal={closeModal}
          richContentData={previewRichContent}
        />
      )}
    </>
  );
};

const AssetsField = ({
  assetIds = [],
  onChange,
}: {
  assetIds: string[];
  onChange: (value: string[]) => void;
}) => {
  const { t } = useTranslation();
  const fullyEnabled = useSelector(
    (state) => state.tenant.galleryStatus !== 'disabled',
  );
  const { showModalId, openModal, closeModal } = useLegacyModal();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const base = useBaseVersion();

  const { cacheAssets, assetCache } = useAssets();

  useEffect(
    () => assetIds && cacheAssets(...assetIds),
    [cacheAssets, assetIds],
  );

  const replaceCurrentAsset = (
    newAssets: DeepReadonly<DigitalAssetBrief[]>,
  ) => {
    if (!assetIds) return;
    const updated = [...assetIds];
    updated.splice(currentIndex, 1, ...newAssets.map((a) => a.id));
    onChange(updated);
    setCurrentIndex(-1);
  };

  // Delete modal layout
  const deleteModal = {
    modalId: modalIds.delete,
    title: capitalizeEveryFirstLetter(
      `${t('global.remove')} ${t('global.image')}`,
    ),
    confirmationStatement: capitalizeFirstLetter(
      `${t('taskMaster.removeConfirmationSingle')}`,
    ),
    clickHandler: () => {
      replaceCurrentAsset([]);
      closeModal();
    },
    actionButtons: [
      {
        text: capitalizeFirstLetter(`${t('global.no_cancel')}`),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(`${t('global.yes_remove')}`),
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  };

  const maxAssets = useMemo(() => {
    // TODO this should be controlled via template
    if (fullyEnabled) return Number.MAX_SAFE_INTEGER;
    else if (base) return base.attributes.assets?.length;
    else return 0; // Don't know yet so disable adding
  }, [fullyEnabled, base]);

  const openModalOnAsset = (
    modalId: (typeof modalIds)[keyof typeof modalIds],
    index: number,
  ) => {
    setCurrentIndex(index);
    openModal(modalId);
  };

  return (
    <>
      <DragDropList
        listClassName="gallery"
        childClassName={(i) => 'asset-gallery-item' + (i ? '' : ' primary')}
        items={assetIds}
        mapItem={(assetId) => (
          <Asset assetId={assetId} assetCache={assetCache} />
        )}
        onReordered={onChange}
        openModalOnIndex={openModalOnAsset}
        canAdd={assetIds?.length < maxAssets}
        canDelete={fullyEnabled}
      />

      {modalIds.update === showModalId && currentIndex !== -1 && (
        <DigitalAssetsModal
          showModal={modalIds.update === showModalId}
          setShowModal={closeModal}
          onClose={closeModal}
          allowedFiles={
            fullyEnabled
              ? undefined // Allow any medium
              : !isAssetVideo(assetCache[currentIndex])
              ? ALL_IMAGES
              : ALL_VIDEOS
          }
          setFinalFiles={replaceCurrentAsset}
          maxFiles={maxAssets}
        />
      )}

      {modalIds.delete === showModalId && (
        <ConfirmationModal
          showModal={modalIds.delete === showModalId}
          setShowModal={closeModal}
          title={deleteModal?.title}
          statement={deleteModal?.confirmationStatement}
          buttons={deleteModal?.actionButtons}
          clickHandler={deleteModal.clickHandler}
          isDanger
        />
      )}

      {modalIds.fullscreen === showModalId && (
        <AssetPreviewModal
          showAssetIndex
          assets={[assetCache[assetIds[currentIndex]]!]}
          showModal={modalIds.fullscreen === showModalId}
          setShowModal={closeModal}
          onClose={closeModal}
          callToAction={{
            text: capitalizeEveryFirstLetter(t('taskMaster.change_asset')),
            callback: () => openModal(modalIds.update),
          }}
        />
      )}
    </>
  );
};

const DependentVariantGalleryRenderer: React.FC<{
  attribute: TemplateAttribute;
  primaryGallery: string[];
}> = ({ attribute, primaryGallery }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { draftItem } = useSelector((s) => s.versions);
  const variants = Object.values(useSelector((s) => s.products.variants ?? {}));
  const options = useSelector((s) => s.products.currentItem?.options ?? []);
  const activeOptions = options.filter((o) => o.isActive);

  const { showModalId, openModal, closeModal } = useLegacyModal();
  const [selectedCombination, setSelectedCombination] = useState<string>('');

  const { cacheAssets, assetCache } = useAssets();

  const value = useMemo(
    () => draftItem?.attributes[attribute.name],
    [draftItem, attribute],
  );

  if (value !== undefined && !isValidAttributeValue(attribute, value))
    throw new AttributeValueError(attribute, value);

  const length = value
    ? attribute.type !== 'html'
      ? value?.length
      : getHTMLLength(value)
    : 0;
  const valid =
    (!attribute.validator?.min || length >= attribute.validator.min) &&
    (!attribute.validator?.max || length <= attribute.validator.max);

  const updateAttribute = (name: string, value: AnyAttributeValue) =>
    dispatch(RVersion.updateDraftAttribute({ name, value }));

  // Cache primary gallery assets
  useEffect(() => {
    if (primaryGallery?.length) {
      cacheAssets(...primaryGallery);
    }
  }, [cacheAssets, primaryGallery]);

  // Create a normalized variant key from active option values
  const getVariantKey = useCallback(
    (variant: Variant) => {
      // If all options are active, just use the variant.id
      if (options.length === activeOptions.length) return variant.id;

      // Otherwise generate a custom key based on active options only
      return activeOptions
        .map(
          (option) =>
            variant.optionValues[option.name]
              ?.toLowerCase()
              .replaceAll(' ', '_') ?? '',
        )
        .filter(Boolean) // Remove empty values
        .join('_');
    },
    [options.length, activeOptions],
  );

  // Process variants to get unique option combinations based on active options
  const uniqueOptionCombinations = useMemo(() => {
    // Keep track of rendered option combinations to avoid duplicates
    const seenOptionCombinations = new Set<string>();
    const combinationVariants = new Map<string, Variant>();

    // First sort the variants to ensure consistent selection
    const sortedVariants = [...variants].sort((a, b) => {
      for (const option of activeOptions) {
        const aValue = a.optionValues[option.name] || '';
        const bValue = b.optionValues[option.name] || '';
        if (aValue !== bValue) return aValue.localeCompare(bValue);
      }
      return 0;
    });

    // Then filter to keep only unique combinations of active options
    for (const variant of sortedVariants) {
      // Skip variants that don't have all active options
      if (
        !activeOptions.every(
          (option) =>
            variant.optionValues &&
            variant.optionValues[option.name] !== undefined,
        )
      ) {
        continue;
      }

      // Create a string representation for this combination
      const combinationLabel = activeOptions
        .map((option) => variant.optionValues[option.name])
        .join(' - ');

      // Only add this variant if we haven't seen this combination before
      if (!seenOptionCombinations.has(combinationLabel)) {
        seenOptionCombinations.add(combinationLabel);
        combinationVariants.set(combinationLabel, variant);
      }
    }

    return Array.from(combinationVariants.entries()).map(
      ([label, variant]) => ({
        label,
        variant,
        variantKey: getVariantKey(variant),
      }),
    );
  }, [variants, activeOptions, getVariantKey]);

  // Get variant image for a combination
  const getImageForCombination = useCallback(
    (combinationLabel: string) => {
      // Special case for single active option
      if (activeOptions.length === 1) {
        // For single option, the combinationLabel is just the option value
        // We need to find any variant with this option value
        const activeOption = activeOptions[0];
        const matchingVariant = variants.find(
          (v) => v.optionValues[activeOption.name] === combinationLabel,
        );

        if (matchingVariant) {
          // Get the first image from the variant overrides
          const variantValue = draftItem?.variantOverrides[
            matchingVariant.id
          ]?.[attribute.name] as string[] | undefined;
          return variantValue?.[0] || '';
        }

        return '';
      }

      // Standard case for multiple active options
      const combination = uniqueOptionCombinations.find(
        (c) => c.label === combinationLabel,
      );
      if (!combination) return '';

      // Get the first image from the variant overrides
      const variantValue = draftItem?.variantOverrides[
        combination.variantKey
      ]?.[attribute.name] as string[] | undefined;
      return variantValue?.[0] || '';
    },
    [
      uniqueOptionCombinations,
      draftItem,
      attribute.name,
      activeOptions,
      variants,
    ],
  );

  // Update all variants with the same option combination
  const updateVariantForCombination = (
    combinationLabel: string,
    assetId: string,
  ) => {
    // Check if this is a direct variant update (for unlabeled groups or inactive options)
    if (combinationLabel.startsWith('variant-')) {
      // Extract the variant ID from the combination label
      const variantId = combinationLabel.replace('variant-', '');

      // Find the variant
      const variant = variants.find((v) => v.id === variantId);
      if (!variant) return;

      // Update just this specific variant
      dispatch(
        RVersion.updateDraftVariantAttribute({
          variantId: variant.id,
          name: attribute.name,
          value: assetId ? [assetId] : [],
        }),
      );

      return;
    }

    // Special case for exactly one active option
    if (activeOptions.length === 1) {
      // When only one option is active, the combinationLabel is just the option value
      // We need to update all variants that have this value for the active option
      const activeOption = activeOptions[0];

      // Find all variants that have this value for the active option
      const matchingVariants = variants.filter(
        (v) => v.optionValues[activeOption.name] === combinationLabel,
      );

      // Update all matching variants
      matchingVariants.forEach((variant) => {
        dispatch(
          RVersion.updateDraftVariantAttribute({
            variantId: variant.id,
            name: attribute.name,
            value: assetId ? [assetId] : [],
          }),
        );
      });

      return;
    }

    // Normal case with multiple active options
    // Find the combination entry
    const combination = uniqueOptionCombinations.find(
      (c) => c.label === combinationLabel,
    );
    if (!combination) return;

    // Create a matcher function for finding variants with the same option values for active options
    const matchesActiveOptions = (v: Variant) => {
      return activeOptions.every(
        (option) =>
          v.optionValues[option.name] ===
          combination.variant.optionValues[option.name],
      );
    };

    // Find all variants that match the active option values of this combination
    const matchingVariants = variants.filter(matchesActiveOptions);

    // Update all matching variants
    matchingVariants.forEach((variant) => {
      const variantKey = getVariantKey(variant);

      dispatch(
        RVersion.updateDraftVariantAttribute({
          variantId: variantKey,
          name: attribute.name,
          value: assetId ? [assetId] : [],
        }),
      );
    });
  };

  // Handle primary gallery image removal to prevent broken references
  useEffect(() => {
    if (!primaryGallery) return;

    // Check all variant overrides for references to images that no longer exist in primary gallery
    uniqueOptionCombinations.forEach((combination) => {
      const assetId = getImageForCombination(combination.label);
      if (assetId && !primaryGallery.includes(assetId)) {
        // If the asset no longer exists in primary gallery, remove it from the variant
        updateVariantForCombination(combination.label, '');
      }
    });
    // If there are no options at all, there's nothing to clean up
  }, [
    primaryGallery,
    uniqueOptionCombinations,
    getImageForCombination,
    activeOptions.length,
    options.length,
    variants,
    draftItem,
    attribute.name,
  ]);

  // Organize variants by primary option for collapsible display or handle inactive options
  const variantsByOption = useMemo(() => {
    // If there's only one active option, return a single group with all that option's values
    if (activeOptions.length === 1) {
      const option = activeOptions[0].name;
      const variantsMap = variants.reduce((t, v) => {
        t[v.optionValues[option]] = v;
        return t;
      }, {} as Record<string, Variant>);

      // Sort the variants alphabetically by option value
      const sortedVariants = Object.values(variantsMap).sort((a, b) => {
        const aValue = a.optionValues[option] || '';
        const bValue = b.optionValues[option] || '';
        return aValue.localeCompare(bValue);
      });

      return [
        {
          option,
          value: '',
          variants: sortedVariants,
        },
      ];
    }

    // Map of primary option value to variants with that value
    const optionGroups: Record<string, Variant[]> = {};

    // Group variants by their primary option value
    variants.forEach((variant) => {
      const primaryOption = activeOptions[0];
      const optionValue = variant.optionValues[primaryOption.name];

      if (optionValue) {
        if (!optionGroups[optionValue]) optionGroups[optionValue] = [];

        // Only add if variant has all active options
        if (
          activeOptions.every(
            (opt) => variant.optionValues[opt.name] !== undefined,
          )
        )
          optionGroups[optionValue].push(variant);
      }
    });

    // Convert to array format for rendering
    return Object.entries(optionGroups)
      .map(([value, groupVariants]) => ({
        option: activeOptions[0].name,
        value,
        variants: groupVariants,
      }))
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [variants, activeOptions]);

  return (
    <div className="dependent-gallery-container">
      {AttributeEditors[attribute.type](
        value,
        (value) => updateAttribute(attribute.name, value),
        false,
        length === 0 || valid,
      )}

      <div className="variant-images-section">
        <h6>{t('version.variant_images')}</h6>
        <p className="section-description">
          {t('version.variant_images_description')}
        </p>
        {variantsByOption.map((group) => {
          return (
            <div key={group.value} className="variant-group">
              {activeOptions.length > 1 && (
                <span className="group-title">
                  {group.option}: {group.value}
                </span>
              )}
              <div className="variant-group-content">
                <div className="variant-options">
                  {group.variants.map((variant) => {
                    const combinationLabel = activeOptions
                      .map((opt) => variant.optionValues[opt.name])
                      .join(' - ');
                    const variantLabel =
                      activeOptions.length === 1
                        ? variant.optionValues[activeOptions[0].name]
                        : activeOptions
                            .slice(1)
                            .map(
                              (opt) =>
                                `${opt.name}: ${
                                  variant.optionValues[opt.name]
                                }`,
                            )
                            .join(', ');

                    // Generate a unique key for the variant
                    const variantKey = combinationLabel;

                    // Get asset ID based on the variant key and active options
                    const assetId = getImageForCombination(combinationLabel);

                    return (
                      <div key={variantKey} className="variant-option">
                        <div className="option-label">{variantLabel}</div>
                        <div
                          className="asset-select"
                          onClick={() => {
                            setSelectedCombination(combinationLabel);
                            openModal(modalIds.update);
                          }}
                        >
                          {assetId ? (
                            <Asset assetId={assetId} assetCache={assetCache} />
                          ) : (
                            <div className="empty-asset">Select an image</div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Asset selection modal */}
      {modalIds.update === showModalId && (
        <DigitalAssetsModal
          showModal={modalIds.update === showModalId}
          setShowModal={closeModal}
          onClose={closeModal}
          restrictToIds={primaryGallery}
          setFinalFiles={(selectedAssets) => {
            // Update the variant with the selected asset
            const selectedAssetId =
              selectedAssets.length > 0 ? selectedAssets[0].id : '';
            updateVariantForCombination(selectedCombination, selectedAssetId);
          }}
          maxFiles={1}
        />
      )}
    </div>
  );
};

const IndependentVariantGalleryRenderer: React.FC<{
  attribute: TemplateAttribute;
}> = ({ attribute }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { draftItem, referenceVersion } = useSelector((s) => s.versions);
  /** The variants for the base (empty array for non-products) */
  const variants = Object.values(useSelector((s) => s.products.variants ?? {}));
  const options = useSelector((s) => s.products.currentItem?.options ?? []);
  const activeOptions = options.filter((o) => o.isActive);
  const [updating, setUpdating] = useState(false);

  // Create a normalized variant key from active option values
  const getVariantKey = (variant: Variant) => {
    // If all options are active, just use the variant.id
    if (options.length === activeOptions.length) return variant.id;

    // Otherwise generate a custom key based on active options only
    return activeOptions
      .map(
        (option) =>
          variant.optionValues[option.name]
            ?.toLowerCase()
            .replaceAll(' ', '_') ?? '',
      )
      .filter(Boolean) // Remove empty values
      .join('_');
  };

  // Create a representation of the variant that only includes active options
  const getVariantOptionString = useCallback(
    (variant: Variant) =>
      activeOptions
        .map((option) => variant.optionValues[option.name] || '')
        .join(' - '),
    [activeOptions],
  );

  // Process variants to remove duplicates when only a subset of options is active
  const processedVariants = useMemo(() => {
    // If all options are active, no need to deduplicate - but still sort alphabetically
    if (options.length === activeOptions.length) {
      return [...variants].sort((a, b) => {
        for (const option of activeOptions) {
          const aValue = a.optionValues[option.name] || '';
          const bValue = b.optionValues[option.name] || '';
          if (aValue !== bValue) return aValue.localeCompare(bValue);
        }
        return 0;
      });
    }

    // Keep track of rendered option combinations to avoid duplicates
    const seenOptionCombinations = new Set<string>();
    const dedupedVariants: Variant[] = [];

    // First sort the variants to ensure consistent selection
    const sortedVariants = [...variants].sort((a, b) => {
      for (const option of activeOptions) {
        const aValue = a.optionValues[option.name] || '';
        const bValue = b.optionValues[option.name] || '';
        if (aValue !== bValue) return aValue.localeCompare(bValue);
      }
      return 0;
    });

    // Then filter to keep only unique combinations of active options
    for (const variant of sortedVariants) {
      // Skip variants that don't have all active options
      if (
        !activeOptions.every(
          (option) =>
            variant.optionValues &&
            variant.optionValues[option.name] !== undefined,
        )
      ) {
        continue;
      }

      // Create a key that represents this combination of active options
      const optionCombination = getVariantOptionString(variant);

      // Only add this variant if we haven't seen this combination before
      if (!seenOptionCombinations.has(optionCombination)) {
        seenOptionCombinations.add(optionCombination);
        dedupedVariants.push(variant);
      }
    }

    return dedupedVariants;
  }, [getVariantOptionString, variants, options, activeOptions]);

  return (
    <>
      {processedVariants.map((variant) => {
        // Generate the variant key based on active options
        const variantKey = getVariantKey(variant);

        // First try to get value using the normalized key, fallback to variant.id for backward compatibility
        const variantValue = (draftItem?.variantOverrides[variantKey]?.[
          attribute.name
        ] ??
          draftItem?.variantOverrides[variant.id]?.[attribute.name] ??
          []) as string & string[];
        const length = variantValue.length;

        const updateVariantAttribute = (value: string | string[]) => {
          // Use the normalized key based on active options instead of the variant ID
          dispatch(
            RVersion.updateDraftVariantAttribute({
              variantId: variantKey, // Use the generated key instead of variant.id
              name: attribute.name,
              value,
            }),
          );
        };

        const valid =
          (!attribute.validator?.min || length >= attribute.validator.min) &&
          (!attribute.validator?.max || length <= attribute.validator.max);

        // First try to get reference content using the normalized key, fallback to variant.id for backward compatibility
        const referenceContent =
          referenceVersion?.variantOverrides?.[variantKey]?.[attribute.name] ??
          referenceVersion?.variantOverrides?.[variant.id]?.[attribute.name] ??
          referenceVersion?.attributes[attribute.name];

        // Get the option combination label
        const optionLabel = getVariantOptionString(variant);

        return (
          <div key={variant.id} className="variant-gallery">
            <div className="top-row">
              <h6>{optionLabel}</h6>
              <span className={'limits' + (valid ? '' : ' invalid')}>
                ({variantValue.length || 0}/{attribute.validator?.max || '∞'})
              </span>
              <PrimaryButton
                className="content-reference-button"
                variant="green"
                size="small"
                disabled={!referenceContent}
                onClick={() => {
                  if (!referenceContent) return;
                  setUpdating(true);
                  updateVariantAttribute(referenceContent);
                  setTimeout(() => setUpdating(false));
                }}
              >
                {capitalizeEveryFirstLetter(t('version.use_reference'))}
              </PrimaryButton>
            </div>
            {AttributeEditors[attribute.type](
              variantValue,
              updateVariantAttribute,
              updating,
              length === 0 || valid,
            )}
          </div>
        );
      })}
    </>
  );
};

export const AttributeEditors = {
  text: ((value, onChange, updating, valid?) => (
    <TextField
      value={value}
      onChange={onChange}
      updating={updating}
      valid={valid}
    />
  )) satisfies FieldCreator<string>,
  html: ((value, onChange, updating, valid?) => (
    <HTMLField value={value} onChange={onChange} valid={valid} />
  )) satisfies FieldCreator<string>,
  richContent: ((richContents = [], onChange) => (
    <RichContentField richContents={richContents} onChange={onChange} />
  )) satisfies FieldCreator<string[]>,
  assets: ((assetIds = [], onChange) => (
    <AssetsField assetIds={assetIds} onChange={onChange} />
  )) satisfies FieldCreator<string[]>,
} as const;

/** An editable view for a version of a product attribute */
export const AttributeField: React.FC<{
  attribute: TemplateAttribute;
}> = ({ attribute }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);

  const { draftItem, referenceVersion } = useSelector((s) => s.versions);
  /** The type of gallery used (independent for non-products) */
  const galleryType = useSelector(
    (s) => s.products.currentItem?.galleryType || 'independent',
  );
  /** The variants for the base (empty array for non-products) */
  const variants = Object.values(useSelector((s) => s.products.variants ?? {}));
  const options = useSelector((s) => s.products.currentItem?.options ?? []);
  const activeOptions = options.filter((o) => o.isActive);

  const value = useMemo(
    () => draftItem?.attributes[attribute.name],
    [draftItem, attribute],
  );
  const referenceContent = useMemo(
    () => referenceVersion?.attributes[attribute.name],
    [referenceVersion, attribute],
  );

  // Only consider it a variant gallery if we have variants and at least one active option
  const isVariantGallery = useMemo(
    () =>
      attribute.type === 'assets' &&
      variants.length > 1 &&
      activeOptions.length > 0,
    [attribute.type, variants, activeOptions.length],
  );

  if (value !== undefined && !isValidAttributeValue(attribute, value))
    throw new AttributeValueError(attribute, value);

  const length = value
    ? attribute.type !== 'html'
      ? value?.length
      : getHTMLLength(value)
    : 0;
  const valid =
    (!attribute.validator?.min || length >= attribute.validator.min) &&
    (!attribute.validator?.max || length <= attribute.validator.max);

  const updateAttribute = useCallback(
    (value: AnyAttributeValue) =>
      dispatch(RVersion.updateDraftAttribute({ name: attribute.name, value })),
    [dispatch, attribute.name],
  );

  const renderContent = () => {
    if (!isVariantGallery) {
      // Render normal attribute view for non-assets or only a single gallery
      return AttributeEditors[attribute.type](
        value,
        updateAttribute,
        updating,
        length === 0 || valid,
      );
    } else if (galleryType === 'dependent') {
      // For dependent gallery mode, render the new dependent gallery component
      // with the primary gallery (base attribute) as the source
      const primaryGallery = (value || []) as string[];
      return (
        <DependentVariantGalleryRenderer
          attribute={attribute}
          primaryGallery={primaryGallery}
        />
      );
    } else {
      // For independent gallery mode, render the standard variant gallery
      return <IndependentVariantGalleryRenderer attribute={attribute} />;
    }
  };

  return (
    <div className="attribute-field">
      <div className="top-row">
        <h5>{capitalizeEveryFirstLetter(attribute.displayName)}</h5>
        {/** Don't show limits and reference for independent galleries as managed at individual gallery level */}
        {!(isVariantGallery && galleryType === 'independent') && (
          <>
            <span className={'limits' + (valid ? '' : ' invalid')}>
              ({length || 0}/{attribute.validator?.max || '∞'})
            </span>
            <PrimaryButton
              className="content-reference-button"
              variant="green"
              size="small"
              disabled={!referenceContent}
              onClick={() => {
                if (!referenceContent) return;
                setUpdating(true);
                updateAttribute(referenceContent);
                // For dependent galleries, also update all the option values
                if (isVariantGallery) {
                  for (const variant of variants) {
                    dispatch(
                      RVersion.updateDraftVariantAttribute({
                        variantId: variant.id,
                        name: attribute.name,
                        value:
                          variant.attributes[attribute.name].slice(0, 1) ?? [],
                      }),
                    );
                  }
                }
                setTimeout(() => setUpdating(false));
              }}
            >
              {capitalizeEveryFirstLetter(t('version.use_reference'))}
            </PrimaryButton>
          </>
        )}
      </div>
      {renderContent()}
    </div>
  );
};
