import { useCallback, useState } from 'react';

// Custom Hook for modal
/**
 * @deprecated Use `dispatch(RModal.open)` and `dispatch(RModal.close)` instead
 * // TODO Remove
 */
const useLegacyModal = () => {
  const [showModalId, setShowModalId] = useState<string | null>();

  const openModal = useCallback(
    (modalId: string) => setShowModalId(modalId),
    [],
  );

  const closeModal = useCallback(() => setShowModalId(null), []);

  return {
    openModal,
    closeModal,
    showModalId,
  };
};

export default useLegacyModal;
