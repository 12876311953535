import { getContextByIds } from '@apis/context';
import {
  countAssets,
  damListConfig,
  getAsset,
  listAssets,
} from '@apis/digitalAssets';
import { fetchRichContent } from '@apis/richContent';
import { listVersions } from '@apis/versions';
import { toast } from '@components/ToastNotification/ToastManager';
import { DigitalAssetBrief } from '@models/digital-asset';
import { ListableActions, ListableState } from '@models/filter';
import { registerSideEffects } from '@redux/sideEffects';
import store, { AppThunk } from '@redux/store';
import { createSlice } from '@reduxjs/toolkit';
import { isError } from '@utils/ImproperError';

type DigitalAssetsState = ListableState<
  DigitalAssetBrief,
  typeof damListConfig
> & {
  selectedAssets: {
    [key: string]: DigitalAssetBrief;
  };
  selectedAsset: DigitalAssetBrief | null;
  showBottomMenu: boolean;
  selectedAssetUsageDetails: {
    [key: string]: unknown;
  };
  /** flag to restrict to specific asset IDs */
  restrictToIds: string[] | null;
};

const initialState: DeepReadonly<DigitalAssetsState> = {
  items: null,
  selectedAssets: {},
  selectedAsset: null,
  showBottomMenu: false,
  page: 0,
  totalItems: 0,
  selectedAssetUsageDetails: {},
  sortBy: 'updatedAt',
  sortOrder: 'desc',
  searchText: '',
  filters: {
    fileType: damListConfig.filters[0].options,
  },
  selectedItems: [],
  restrictToIds: null, // Initialize as null
};

type ListActions = ListableActions<typeof damListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      Object.assign(state.filters, payload);
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
      state.selectedAssets = {};
      // Don't reset restrictToIds - it should persist across resets
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: DigitalAssetBrief[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    removeItems: (
      state,
      { payload }: { payload: DeepReadonly<DigitalAssetBrief[]> },
    ) => {
      const { items } = state;
      if (!items) return;
      for (const item of payload) {
        const index = items.findIndex(({ id }) => id === item.id);
        if (index !== -1) items.splice(index, 1);
      }
    },
    setSelectedItems: (
      state,
      { payload }: { payload: DigitalAssetBrief[] },
    ) => {
      state.selectedItems = payload;
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters as DeepWritable<
        typeof initialState.filters
      >;
      caseReducers.resetResults(state);
    },
    toggleSelected: (
      state,
      { payload }: { payload: DeepReadonly<DigitalAssetBrief> },
    ) => {
      const { selectedAssets } = state;
      if (selectedAssets[payload.id]) delete selectedAssets[payload.id];
      // Assert mutable as it is likely from our state and we're currently in a writable mode
      else selectedAssets[payload.id] = payload as DeepWritable<typeof payload>;
    },
    resetSelected: (state) => {
      state.selectedAssets = {};
    },
    setCurrent: (state, { payload }: { payload: DigitalAssetBrief | null }) => {
      state.selectedAsset = payload;
    },
    updateCurrent: (
      state,
      { payload }: { payload: Partial<DigitalAssetBrief> },
    ) => {
      if (!state.selectedAsset)
        throw new Error('Cannot update asset if none selected');
      state.selectedAsset = {
        ...state.selectedAsset,
        ...payload,
      };
    },
    setUsageDetails: (
      state,
      { payload }: { payload: { id: string; data: unknown } },
    ) => {
      state.selectedAssetUsageDetails[payload.id] = payload.data;
    },
    resetUsageDetails: (state) => {
      state.selectedAssetUsageDetails = initialState.selectedAssetUsageDetails;
    },
    setBottomMenuVisible: (state, { payload }: { payload: boolean }) => {
      state.showBottomMenu = payload;
    },
    setRestrictToIds: (state, { payload }: { payload: string[] | null }) => {
      state.restrictToIds = payload;
    },
  },
});

const thunks = {
  getDigitalAssetByIdSaga:
    (assetId: string): AppThunk =>
    async (dispatch) => {
      try {
        const asset = await getAsset(assetId);
        dispatch(RAsset.setCurrent(asset));
      } catch (errorObj: any) {
        const { errors } = errorObj;
        dispatch(RAsset.setCurrent(null));
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
  getDigitalAssetUsageDetailsSaga:
    (
      moduleId: 'contexts' | 'richContent' | 'contextualisedProducts',
      ids: string[],
    ): AppThunk =>
    async (dispatch) => {
      let items: { items: unknown[] } | Error;
      switch (moduleId) {
        case 'contextualisedProducts':
          items = await listVersions({
            filter: `IN(id,${ids.join(',')}`,
          });
          break;
        case 'richContent':
          items = await fetchRichContent({ ids });
          break;
        case 'contexts':
          items = await getContextByIds(ids).then((r) =>
            isError(r) ? r : { items: r },
          );
          break;
      }

      if (isError(items)) {
        console.error(items);
        toast.show(items);
        dispatch(
          RAsset.setUsageDetails({
            id: moduleId,
            data: null,
          }),
        );
      } else dispatch(RAsset.setUsageDetails({ id: moduleId, data: items }));
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

const currentFetch: {
  id: number;
  type: (typeof actions)[keyof typeof actions]['type'];
} = { id: 0, type: actions.incrementPage.type };

registerSideEffects({
  name: 'Fetch next assets',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
    actions.removeItems,
    actions.setRestrictToIds, // Add dependency on this action
  ],
  execute: async ({ dispatch, state, action }) => {
    // Skip API fetching if we're restricting to specific asset IDs
    // The component will handle fetching those assets directly
    if (state.assets.restrictToIds !== null) return;

    try {
      const thisFetch = (currentFetch.id += 1);
      currentFetch.type =
        action.type as (typeof actions)[keyof typeof actions]['type'];

      const assetsPromise = listAssets(state.assets);
      const countPromise =
        state.products.page === 0 ? countAssets(state.assets) : null;

      let assets = await assetsPromise;

      // A new request was sent so abort
      if (
        (thisFetch !== currentFetch.id &&
          currentFetch.type !== actions.incrementPage.type) ||
        store.getState().assets.restrictToIds
      )
        return;

      // If it's a refetch because assets were removed, deduplicate
      if (action.type === 'assets/removeItems')
        assets = assets.filter(
          // Only keep results which are not already in items
          (a1) => !state.assets.items?.find((a2) => a2.id === a1.id),
        );
      dispatch(RAsset.addItems(assets));

      if (countPromise) {
        const count = await countPromise;
        dispatch(RAsset.setItemCount(count));
      }
    } catch (e) {
      dispatch(RAsset.setErrorState());
    }
  },
});

export const RAsset = Object.assign(actions, thunks);

export default reducer;
