import { TestStrategy } from '@apis/aiService';
import { ReactComponent as TargetingSvg } from '@assets/icons/test-targeting.svg';
import { colors } from '@constants/colors';

import './TestTargets.scss';

type ColorSet = {
  primary: string;
  background: string;
  secondary: string;
};

export const colorsByTestLevel: {
  [key in TestStrategy['level']]: ColorSet;
} = {
  basic: {
    primary: colors.emerald,
    background: colors.emerald9,
    secondary: colors.emerald7,
  },
  advanced: {
    primary: colors.capri,
    background: colors.capri10,
    secondary: colors.capri7,
  },
  expert: {
    primary: colors.bleuDeFrance,
    background: colors.bleuDeFrance9,
    secondary: colors.bleuDeFrance7,
  },
};

export const TestTargets: React.FC<{
  test: TestStrategy;
}> = ({ test }) => {
  const activeDescendants = [...test.attributes];
  if (activeDescendants.some)
    if (activeDescendants.some((att) => att.startsWith('assets_')))
      activeDescendants.push('assets');

  const colorSet = colorsByTestLevel[test.level];

  const cssDefinitions = {
    '--test-targets-primary-color': colorSet.primary,
    '--test-targets-secondary-color': colorSet.secondary,
    '--test-targets-background-color': colorSet.background,
  } as React.CSSProperties;

  return (
    <div className="test-targets" style={cssDefinitions}>
      <TargetingSvg aria-activedescendant={activeDescendants.join(' ')} />
    </div>
  );
};
