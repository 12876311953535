import { HeuristicRecord } from '@models/index';
import store from '@redux/store';
import { ensureError } from '@utils/ImproperError';
import axios, { Method } from 'axios';
import deepEqual from 'deep-equal';
import { History } from 'history';
import { FromSchema, JSONSchema } from 'json-schema-to-ts';

/**
 * Creating axios instances with interceptors attached for reusability.
 *
 * @example clientWithToken.httpVerb('/remaining-slug-url', dataIfAny) => Promise like axios;
 */
const hostName = window.location.hostname;

/** A error thrown by {@link clientWithToken} */
export class ApiError extends Error {
  public errors: string[];
  public body: unknown;
  public status: number;

  // TODO | The type of response isn't guaranteed to be correct. Verify with Axios docs
  constructor(public response: { status: number; data: any }) {
    super(
      response.data?.errors?.[0]?.message ??
        response.data?.errors?.[0] ??
        response.data.message ??
        'Unexpected API error',
    );
    this.errors = response.data?.errors ?? response.data.details ?? [];
    this.body = response.data?.body || response?.data || null;
    this.status = response?.status;
  }
}

const TLD_NAME = hostName.includes('.ch') ? 'ch' : 'com'; // TOP LEVEL DOMAIN
export const BASE =
  hostName !== 'localhost'
    ? `https://${hostName.split('.')[0]}-api.prodport.${TLD_NAME}/`
    : process.env.REACT_APP_BASE_API_URL;

export const clientWithToken = axios.create({ baseURL: BASE });

/** Default page size for APIs */
export const PAGE_SIZE = 20;

clientWithToken.interceptors.request.use((config) => {
  const kcInstance = store.getState().keyCloak.keyCloak;
  config.headers.Authorization = `Bearer ${kcInstance.token}`;
  config.headers['X-TenantID'] = kcInstance.clientId;
  return config;
});

const onResponseSuccess = (response: any) => {
  return response;
};

export const setUpForbiddenInterceptor = (history: History<unknown>) => {
  clientWithToken.interceptors.response.use(onResponseSuccess, (err) => {
    if (axios.isCancel(err)) return Promise.reject(err);

    const status = err.status || err.response.status;
    if (status === 403) history.push('/forbidden');
    if (status === 401) store.getState().keyCloak.keyCloak.logout();

    // getting arraybuffer response for downloading zip file
    // needed to convert arraybuffer response to actual JSON response
    if (err.response.config.responseType === 'arraybuffer') {
      const uint8View = new Uint8Array(err.response.data);
      const jsonString = Buffer.from(uint8View).toString('utf8');
      err.response.data = JSON.parse(jsonString);
    }
    return Promise.reject(new ApiError(err.response));
  });
};

export type ListParams = {
  page?: number;
  limit?: number;
  query?: string;
  sortBy?: string;
  sortOrder?: string;
  filter?: string;
  filters?: Record<string, string[] | string | undefined>;
};

const isOkStatus = (status: number) => status >= 200 && status < 300;

/**
 * @example
 * ```ts
 * const api = createApi<
 *   {
 *     a: { type: 'string' };
 *     b: { type: ['number', 'null'] };
 *     c: { type: 'object' };
 *   },
 *   ['a', 'b'],
 *   [parentId: string]
 * >('/prefix/:parentId/foo');
 * ```
 */
export const createApi = <
  Schema extends Record<string, JSONSchema>,
  RequiredProps extends readonly string[],
  PathParams extends string[] = [],
>(
  basePath: `/${string}`,
) => {
  /**
   * Extracts the keys of {@link Object} where the value of the key extends {@link Extends}
   * @example
   * KeysWhere<{ a: 1; b: 2; c: 2 }, 2> // "b" | "c"
   */
  type KeysWhere<Record, Extends> = {
    [K in keyof Record]: Record[K] extends Extends ? K : never;
  } extends infer R
    ? R[keyof R]
    : never;

  type DefaultProps = {
    [K in keyof Schema]: Schema[K] extends { default: unknown } ? K : never;
  }[keyof Schema];

  type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
  type FromSchemaWithOptionalDefaults<T extends JSONSchema> =
    FromSchema<T> extends infer R
      ? // @ts-expect-error ts(2344) We know that T['properties'] is guaranteed to be part of the schema result
        PartialBy<R, DefaultProps>
      : never;

  type ProtectedProps = Extract<KeysWhere<Schema, { readOnly: true }>, string>;

  type BaseResponseProps = FromSchema<{
    type: 'object';
    properties: Schema;
    required: RequiredProps;
    additionalProperties: false;
  }>;
  type ResponseProps = BaseResponseProps;

  type Response = ResponseProps extends Record<string, unknown>
    ? HeuristicRecord<ResponseProps>
    : never;

  type PostBody = FromSchemaWithOptionalDefaults<{
    type: 'object';
    properties: Omit<Schema, ProtectedProps>;
    required: RequiredProps;
    additionalProperties: false;
  }> & { id?: string };

  type PatchBody = Partial<PostBody>;

  const getPathname = (
    pathParams: string[],
    postfix?: string,
  ): `/${string}` => {
    let path = basePath;
    for (const pathParam of pathParams) {
      const start = path.indexOf('/:');
      const end = path.indexOf('/', start + 1);
      path = (path.substring(0, start) +
        '/' +
        pathParam +
        path.substring(end)) as `/${string}`;
    }
    if (postfix) path += postfix;
    return path as `/${string}`;
  };

  return {
    basePath,
    types: {
      response: {} as Response,
      post: {} as PostBody,
      patch: {} as PatchBody,
    },

    list: async (
      ...args: [...PathParams, params?: ListParams]
    ): Promise<
      { items: Response[]; totalCount: number } | ApiError | Error
    > => {
      const params = (args.pop() as ListParams | undefined) || {};
      const pathParams = args as string[];

      const searchParams = new URLSearchParams();
      if ('searchText' in params && typeof params.searchText === 'string')
        searchParams.append('query', params.searchText);
      if (params.query) searchParams.append('query', params.query);
      if (params.page) searchParams.append('page', params.page + '');
      if (params.limit) searchParams.append('limit', params.limit + '');
      if (params.sortBy) searchParams.append('sortBy', params.sortBy);
      if (params.sortOrder) searchParams.append('sortOrder', params.sortOrder);
      if (params.filter) searchParams.append('filter', params.filter);
      if (params.filters) {
        const andCases: string[] = [];
        for (const filter in params.filters) {
          const values = params.filters[filter];
          if (values != null)
            andCases.push(
              Array.isArray(values)
                ? `IN(${filter},${values.join(',')})`
                : `EQ(${filter},${values})`,
            );
        }
        if (andCases.length > 0)
          searchParams.append(
            'filter',
            andCases.length > 1 ? `AND(${andCases.join(',')})` : andCases[0],
          );
      }

      try {
        const response = await clientWithToken.get<Response[]>(
          getPathname(pathParams, '?' + searchParams),
        );
        if (response.status !== 200) return new ApiError(response);
        return {
          items: response.data,
          totalCount: Number(response.headers['total-item-count']),
        };
      } catch (err) {
        return ensureError(err);
      }
    },
    post: async (
      ...args: [...PathParams, body: PostBody]
    ): Promise<Response | ApiError | Error> => {
      const body = args.pop() as PostBody;
      const pathParams = args as string[];

      try {
        const response = await clientWithToken.post<Response>(
          getPathname(pathParams),
          body,
        );
        if (!isOkStatus(response.status)) return new ApiError(response);
        return response.data;
      } catch (err) {
        return ensureError(err);
      }
    },
    get: async (
      ...args: [...PathParams, id: string]
    ): Promise<Response | ApiError | Error> => {
      const id = args.pop() as string;
      const pathParams = args;

      try {
        const response = await clientWithToken.get<Response>(
          getPathname(pathParams, '/' + id),
        );
        if (response.status !== 200) return new ApiError(response);
        return response.data;
      } catch (err) {
        return ensureError(err);
      }
    },
    patch: async (
      ...args: [...PathParams, id: string, body: PatchBody]
    ): Promise<Response | ApiError | Error> => {
      const body = args.pop() as PatchBody;
      const id = args.pop() as string;
      const pathParams = args as string[];

      try {
        const response = await clientWithToken.patch<Response>(
          getPathname(pathParams, '/' + id),
          body,
        );
        if (response.status !== 200) return new ApiError(response);
        return response.data;
      } catch (err) {
        return ensureError(err);
      }
    },
    delete: async (
      ...args: [...PathParams, id: string]
    ): Promise<Response | ApiError | Error> => {
      const id = args.pop() as string;
      const pathParams = args;

      try {
        const response = await clientWithToken.delete<Response>(
          getPathname(pathParams, '/' + id),
        );
        if (response.status !== 200) return new ApiError(response);
        return response.data;
      } catch (err) {
        return ensureError(err);
      }
    },
    request: async <T>(
      ...args: [
        ...PathParams,
        path: string,
        init?: {
          method?: Method;
          body?: unknown;
        },
      ]
    ): Promise<T | ApiError | Error> => {
      const last = args.pop();
      const { method = 'GET', body } = typeof last === 'object' ? last : {};
      const path = typeof last === 'string' ? last : (args.pop() as string);
      const pathParams = args as string[];

      try {
        const response = await clientWithToken.request<T>({
          url: getPathname(pathParams, path),
          method,
          data: body,
        });
        if (!isOkStatus(response.status)) return new ApiError(response);
        return response.data;
      } catch (err) {
        return ensureError(err);
      }
    },
  };
};

/**
 * Compares two record and creates a partial patch out of all the deep unequal values
 *
 * @example
 * ```ts
 * createPatch({ a: 1, b: 2, c: { d: 3 }, e: { f: 4 } }, { a: 1, b: 3, c: { d: 3 }, e: { f: 5 } });
 * // ^ Results in `{ b: 3, e: { f: 5 } }`
 * ```
 */
export const createPatch = <T extends Record<string, unknown>>(
  original: T,
  updated: T,
): Partial<T> => {
  const patch: Partial<T> = {};
  for (const key in original) {
    if (!deepEqual(original[key], updated[key])) patch[key] = updated[key];
  }
  return patch;
};

export const isBillingError = (err: unknown): err is ApiError =>
  err instanceof ApiError && err.status === 402;
