import { Page } from '@apis/pages';
import { Product } from '@apis/products';
import { publishVersion, unpublishVersion, Version } from '@apis/versions';
import store from '@redux/store';

export class VersionValidationError extends Error {
  constructor(
    public version: Version.Post | Version.Patch,
    public field: keyof Version.Post,
    public issue: string,
  ) {
    super(`Invalid version field. ${field} ${issue} (got "${version[field]}")`);
  }
}

/** @returns a clean {@link Version.Post}/{@link Version.Patch} from the provided {@link Version.Post}/{@link Version.Patch} */
export const cleanVersion = <T extends Version.Patch | Version.Post>(
  version: T,
): T => ({
  ...version,
  ...(version.title != null && { title: version.title.trim() }),
  ...(version.type === 'test' && { contextIds: [] }),
});

/**
 * First updates the version to standardize the attributes (e.g. trimming title whitespace) and then
 * validates all fields.
 *
 * @returns Any validation errors encountered
 */
export const validateVersion = (
  version: Version.Post | Version.Patch,
): VersionValidationError[] => {
  version = cleanVersion(version); // Check the final form instead
  const validationErrors: VersionValidationError[] = [];

  if (version.title != null && version.title.length === 0)
    validationErrors.push(
      new VersionValidationError(version, 'title', 'must have a length'),
    );

  if (
    version.type === 'personalize' &&
    (!version.contextIds || version.contextIds.length === 0)
  )
    validationErrors.push(
      new VersionValidationError(
        version,
        'contextIds',
        'must have at least 1 context',
      ),
    );

  return validationErrors;
};

export const createEmptyVersion = (
  type: Version['parent']['type'],
  parentId: string,
): Version => ({
  id: '',
  title: '',
  publishedAt: null,
  expiresAt: null,
  type: 'test',
  trafficPercentage: null,
  contextIds: [],
  attributes: {},
  experimentIds: [],
  referenceVersion: null,
  status: 'draft',
  thumbnail: null,
  parent: { type, id: parentId },
  variantOverrides: {},
  createdAt: 0,
  updatedAt: 0,
  tenantId: '',
});

export const openPreview = async (
  versionId: string,
  parent: DeepReadonly<Product | Page>,
) => {
  // Wait for 4 seconds
  await new Promise((r) => setTimeout(r, 4000));
  const { storeUrl } = store.getState().tenant;
  const pathname =
    'pathname' in parent ? parent.pathname : `/products/${parent.handle}`;
  const url = new URL(pathname, storeUrl);
  url.searchParams.set('versionId', versionId);
  url.searchParams.set('prodport-preview', 'true');
  window.open(url, '_blank');
};

/** If version is published, unpublishes it. Else, publishes it. */
export const toggleVersionActive = async (
  version: Pick<Version, 'id' | 'status'>,
) =>
  version.status === 'published'
    ? unpublishVersion(version.id)
    : publishVersion(version.id);

/** Converts a base product or page to a version */
export const baseToVersion = (base: Page | Product): Version => ({
  id: base.id,
  title: base.title,
  parent: { type: 'price' in base ? 'product' : 'page', id: base.id },
  publishedAt: 0,
  expiresAt: null,
  type: 'test',
  trafficPercentage: null,
  contextIds: [],
  attributes: {},
  experimentIds: [],
  referenceVersion: null,
  status: 'published',
  thumbnail: base.thumbnail,
  variantOverrides: {},
  createdAt: 0,
  updatedAt: 0,
  tenantId: base.tenantId,
});
