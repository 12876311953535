import { previewVersion } from '@apis/versions';
import fallbackImage from '@assets/illustrations/57_NoImage.svg';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { PromptComponent } from '@components/PromptComponent/PromptComponent';
import { TestTargets } from '@components/TestTargets/TestTargets';
import { toast } from '@components/ToastNotification/ToastManager';
import { RModal } from '@redux/slices/modal';
import { RVersion } from '@redux/slices/version';
import { isError } from '@utils/ImproperError';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { openPreview } from '@utils/version';
import deepEqual from 'deep-equal';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useBaseVersion } from '../../hooks/useBaseVersion';
import { useVersion } from '../../hooks/useVersion';
import { AttributeField } from './AttributeField/AttributeField';
import { AttributeView } from './AttributeView/AttributeView';
import { DiscardChangesModal } from './DiscardChangesModal/DiscardChangesModal';

import './ContentEditor.scss';

/** Edits the redux `versions.draftItem` comparing it to `versions.currentItem` and leveraging `versions.testStrategy`, `versions.reference`, and `templates.template` */
export const ContentEditor: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fallbackVersionId = useMemo(
    () =>
      crypto.randomUUID() ??
      Math.round(Math.random() * Number.MAX_SAFE_INTEGER) + '',
    [],
  );

  const { template } = useSelector((s) => s.templates);
  const originalVersion = useVersion();
  const { draftItem: draftVersion } = useSelector((s) => s.versions);
  let { testStrategy } = useSelector((s) => s.versions);
  testStrategy = testStrategy || {
    id: '_TEMP_WORKAROUND_',
    level: 'expert',
    attributes:
      template?.attributes.filter((a) => a.customizable).map((a) => a.name) ||
      [],
    title: draftVersion?.title || '',
    description: 'Custom Experience',
    type: draftVersion?.type || 'test',
  };

  const base = useBaseVersion();

  const contentDidChange = useMemo(
    () =>
      !deepEqual(originalVersion?.attributes, draftVersion?.attributes) ||
      !deepEqual(
        originalVersion?.variantOverrides ?? {},
        draftVersion?.variantOverrides ?? {},
      ),
    [
      originalVersion?.attributes,
      draftVersion?.attributes,
      originalVersion?.variantOverrides,
      draftVersion?.variantOverrides,
    ],
  );

  const customizableAttributes =
    template?.attributes.filter((attribute) => attribute.customizable) || [];
  const testAttributes = customizableAttributes.filter((attribute) => {
    const list = testStrategy.attributes;
    const name = attribute.name;
    const nonVariantName = name.substring(0, name.indexOf('_'));
    return list.includes(name) || list.includes(nonVariantName);
  });

  const versionId = draftVersion?.id || fallbackVersionId;

  return (
    <>
      <div className="content-editor">
        <div className="top-box">
          <div className="info">
            <div className="test-strategy">
              <TestTargets test={testStrategy} />
              <h3>
                <span>
                  {capitalizeFirstLetter(t('contentEditor.testingFor'))}
                </span>
                <span>{testStrategy.title}</span>
              </h3>
            </div>
            <div className="product">
              <img
                src={base?.thumbnail || fallbackImage}
                alt={'Product Thumbnail'}
              />
              <h3>
                <span>
                  1{' '}
                  {capitalizeEveryFirstLetter(
                    t(
                      'global.' +
                        (base && 'pageId' in base ? 'page' : 'product'),
                    ),
                  )}
                </span>
                <span>{base?.title}</span>
              </h3>
            </div>
          </div>
          <div className="content">
            <h4>
              {capitalizeEveryFirstLetter(t('contentEditor.versionContent'))}
            </h4>
            {testAttributes.map((attribute, i) => (
              <AttributeField attribute={attribute} key={i} />
            ))}
          </div>
        </div>
        <div className="Card compare-view">
          <div className="heading">{t('contentEditor.heuristic_vs_base')}</div>
          <div className="compare">
            <div className="version">
              <div className="info">
                <h4>
                  {capitalizeEveryFirstLetter(t('contentEditor.testVersion'))}
                </h4>
                <PrimaryButton
                  className="preview-button"
                  size="small"
                  variant="green"
                  onClick={async () => {
                    if (!(base && draftVersion)) return;

                    dispatch(RModal.open({ type: 'VersionPreviewModal' }));
                    const result = await previewVersion(
                      versionId,
                      draftVersion.attributes,
                      draftVersion.variantOverrides,
                    );
                    if (isError(result)) toast.show(result);
                    else await openPreview(versionId, base);

                    dispatch(RModal.close());
                  }}
                >
                  {capitalizeEveryFirstLetter(t('global.preview'))}
                </PrimaryButton>
              </div>
              <div className="content">
                {customizableAttributes.map((attribute, i) => (
                  <AttributeView
                    attribute={attribute}
                    type={
                      /** If the test doesn't allow customization, render base value */
                      testAttributes.includes(attribute) ? 'version' : 'base'
                    }
                    key={i}
                  />
                ))}
              </div>
            </div>
            <div className="version">
              <div className="info">
                <h4>{capitalizeEveryFirstLetter(t('global.base_version'))}</h4>
              </div>
              <div className="content">
                {customizableAttributes.map((attribute, i) => (
                  <AttributeView attribute={attribute} type="base" key={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PromptComponent
        whenToActive={contentDidChange}
        modal={
          <DiscardChangesModal
            discardChanges={() => dispatch(RVersion.setDraftItem(null))}
          />
        }
      />
    </>
  );
};
