import { fetchTemplate, Template } from '@apis/template';
import { toast } from '@components/ToastNotification/ToastManager';
import { registerSideEffects } from '@redux/sideEffects';
import { AppThunk } from '@redux/store';
import { createSlice } from '@reduxjs/toolkit';

type TemplateState = {
  template: Template | null;
};

const initialState: TemplateState = {
  template: null,
};

const { actions, reducer } = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplate: (state, { payload }: { payload: Template | null }) => {
      state.template = payload;
    },
  },
});

const thunks = {
  fetchTemplate:
    (pid: string, type: 'product' | 'page'): AppThunk =>
    async (dispatch) => {
      dispatch(RTemplate.setTemplate(null));
      const template = await fetchTemplate(pid, type);
      if (template instanceof Error) toast.show(template);
      else dispatch(RTemplate.setTemplate(template));
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RTemplate = Object.assign(actions, thunks);

export default reducer;
