import {
  getPage,
  getPageContent,
  listPages,
  Page,
  pagesListConfig,
  PageWithAttributes,
} from '@apis/pages';
import { toast } from '@components/ToastNotification/ToastManager';
import { ListableActions, ListableState } from '@models/filter';
import { registerSideEffects } from '@redux/sideEffects';
import { AppThunk } from '@redux/store';
import { createSlice } from '@reduxjs/toolkit';
import { ensureError } from '@utils/ImproperError';

// TODO make current item part of listableState
type PagesState = ListableState<Page, typeof pagesListConfig> & {
  currentItem: PageWithAttributes | null;
};

const initialState: PagesState = {
  sortBy: 'relevanceScore',
  sortOrder: 'desc',
  filters: { status: undefined },
  searchText: '',
  page: 0,
  items: null,
  totalItems: 0,
  currentItem: null,
  selectedItems: [],
};

type ListActions = ListableActions<typeof pagesListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      Object.assign(state.filters, payload);
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
      state.currentItem = null;
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: Page[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    setSelectedItems: (state, { payload }: { payload: Page[] }) => {
      state.selectedItems = payload;
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters;
      caseReducers.resetResults(state);
    },
    setCurrentItem: (
      state,
      { payload }: { payload: PageWithAttributes | null },
    ) => {
      state.currentItem = payload;
    },
  },
});

const thunks = {
  loadPage:
    (pageId: string): AppThunk =>
    async (dispatch, getState) => {
      // Don't load if already loaded
      if (pageId === getState().pages.currentItem?.id) return;

      dispatch(RPages.setCurrentItem(null));
      const pageContentPromise = getPageContent(pageId);
      const page = await getPage(pageId);
      const pageContent = await pageContentPromise;
      if (page instanceof Error) throw toast.show(page);
      else if (pageContent instanceof Error) throw toast.show(pageContent);
      else {
        const pageWithAttributes = Object.assign(page, {
          attributes: pageContent.attributes,
        });
        dispatch(RPages.setCurrentItem(pageWithAttributes));
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects({
  name: 'Fetch next pages',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
  ],
  execute: async ({ dispatch, state }) => {
    try {
      const listResult = await listPages(state.pages);
      if (listResult instanceof Error) return toast.show(listResult);

      const { items, totalCount } = listResult;
      dispatch(RPages.addItems(items));
      dispatch(RPages.setItemCount(totalCount));
    } catch (err) {
      toast.show(ensureError(err));
      console.error(err);
      dispatch(RPages.setErrorState());
    }
  },
});

export const RPages = Object.assign(actions, thunks);

export default reducer;
