import {
  ListConfiguration,
  ListablePayload,
  ListableState,
} from '@models/filter';
import { AttributeValueMap } from '@models/product';
import { createApi } from '.';

export namespace ProductApi {
  export namespace List {
    export type State = ListableState<Product, typeof productListConfig>;
    export type Query = ListablePayload<typeof productListConfig>;
  }
}

export const productListConfig = {
  sort: [
    { sortBy: 'relevanceScore', sortOrder: 'desc' },
    { sortBy: 'title', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'price', sortOrder: 'asc' },
    { sortBy: 'price', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'status',
      multiple: false,
      hidden: true,
      options: ['draft', 'active', 'archived'],
    },
  ],
} as const satisfies ListConfiguration;

export const productApi = createApi<
  {
    title: { type: 'string'; description: 'The product title/name' };
    description: {
      type: 'string';
      description: 'The (HTML) description on the product';
    };
    thumbnail: {
      type: ['string', 'null'];
      description: 'URL for preview image';
    };
    handle: {
      type: 'string';
      description: 'The product handle i.e. human-friendly ID (usually found in URL)';
    };
    status: {
      type: 'string';
      default: 'active';
      enum: ['draft', 'active', 'archived', 'deleted'];
      description: 'The status of the product';
    };
    relevanceScore: {
      type: 'number';
      default: 0;
      description: 'An internal scoring of how relevant a product is to work on for sorting purposes';
    };
    price: { type: 'number'; description: 'Price of product' };
    sourceTemplateId: {
      type: 'string';
      description: 'The page template used in the frontend to render (e.g. for Shopify `product.other.liquid` -> `product.other`)';
    };
    taxonomies: {
      type: 'array';
      items: { type: 'string' };
      description: 'The taxonomy "path"';
    };

    options: {
      type: 'array';
      items: {
        type: 'object';
        properties: {
          name: {
            type: 'string';
            description: 'Option name (e.g., color, size)';
          };
          values: {
            type: 'array';
            items: { type: 'string' };
            description: 'Possible values for the option';
          };
          isActive: {
            type: 'boolean';
            description: 'Option status enabled/ disabled';
          };
        };
        additionalProperties: false;
        required: ['name', 'values', 'isActive'];
      };
      description: 'Possible options on product E.g., [{name: "color", values: ["red", "blue"], isActive: true}]';
    };
    defaultVariantId: {
      type: 'string';
      description: 'The default variant';
    };
    variantIds: {
      type: 'array';
      items: { type: 'string' };
      description: 'The list of variant IDs';
    };
    galleryType: {
      type: 'string';
      enum: ['independent', 'dependent'];
      default: 'independent';
      description: 'The gallery mode selected for this product';
    };
    experiences: {
      type: 'object';
      properties: {
        total: {
          type: 'number';
          default: 0;
          description: 'Total number of experiences';
        };
        live: {
          type: 'number';
          default: 0;
          description: 'Number of live experiences';
        };
        draft: {
          type: 'number';
          default: 0;
          description: 'Number of draft experiences';
        };
        disabled: {
          type: 'number';
          default: 0;
          description: 'Number of disabled experiences';
        };
      };
      required: ['total', 'live', 'draft', 'disabled'];
      default: {
        total: 0;
        live: 0;
        draft: 0;
        disabled: 0;
      };
      description: 'Details on how many experiences in each category exist';
    };
  },
  [
    'title',
    'description',
    'thumbnail',
    'handle',
    'price',
    'sourceTemplateId',
    'taxonomies',
    'options',
    'defaultVariantId',
    'variantIds',
  ]
>('/products');

export type Product = typeof productApi.types.response;

export const variantApi = createApi<
  {
    productId: {
      type: 'string';
      description: 'The product ID in the external system (e.g., Shopify)';
    };
    price: {
      type: 'number';
      description: "The variant-specific price in the tenant's default currency";
    };
    sku: {
      type: 'string';
      description: 'The SKU associated with the variant ID';
    };
    optionValues: {
      type: 'object';
      additionalProperties: { type: 'string' };
      description: "The values for the associated product's options (e.g., { color: red, size: large, material: wood })";
    };
    attributes: {
      type: 'object';
      additionalProperties: {
        type: ['array', 'string'];
        items: { type: 'string'; minLength: 1 };
      };
      description: 'The actual content for this variant';
    };
  },
  ['productId', 'price', 'sku', 'optionValues', 'attributes'],
  [productId: string]
>('/products/:productId/variants');

export type Variant = typeof variantApi.types.response;

export type ProductWithAttributes = Product & {
  /** The attributes from the default variant */
  attributes: AttributeValueMap;
  /** Other variant's attributes */
  variantOverrides: {
    [variantId: string]: AttributeValueMap;
  };
};

export const getProduct = productApi.get;
export const patchProduct = productApi.patch;

export const listProducts = productApi.list;

/** Update a product's options settings (order & active) */
export const updateProductOptions = (
  productId: string,
  options: Array<Omit<Product['options'][number], 'values'>>,
) =>
  productApi.request<Product>(`/${productId}/options`, {
    method: 'PUT',
    body: options,
  });

/** By default, lists all variants for a product. Specify limit to change that behavior */
export const listVariants = (productId: string, page = 0, limit = 100) =>
  variantApi.list(productId, { page, limit });

export const getVariant = variantApi.get;

export const getDefaultVariant = (productId: string) =>
  variantApi.get(productId, 'default');
