import { AIService } from '@apis/aiService';
import { ApiError } from '@apis/index';
import { createVersion, publishVersion, Version } from '@apis/versions';
import { ContentEditor } from '@components/ContentEditor/ContentEditor';
import { CreatingTestIcon } from '@components/Icons/CreatingTestIcon';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { toast } from '@components/ToastNotification/ToastManager';
import { RModal } from '@redux/slices/modal';
import { RSpinner } from '@redux/slices/spinner';
import { RTenant } from '@redux/slices/tenant';
import { RVersion } from '@redux/slices/version';
import { isError } from '@utils/ImproperError';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { createEmptyVersion } from '@utils/version';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBaseVersion } from '../../../../hooks/useBaseVersion';

import './style.scss';

export const VersionView: React.FC = () => {
  const { t } = useTranslation();
  const { currentItem: product } = useSelector((s) => s.products);
  const { draftItem: draftVersion, testStrategy } = useSelector(
    (s) => s.versions,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const base = useBaseVersion();
  useEffect(() => void dispatch(RVersion.setReferenceVersion(base)), [base]);

  useEffect(() => {
    if (!(testStrategy && product)) return;

    const draftVersion = createEmptyVersion('product', product.id);
    draftVersion.title = testStrategy.title;
    draftVersion.id = crypto.randomUUID();
    dispatch(RVersion.setCurrentItem(draftVersion));

    AIService.generate({
      testId: testStrategy.id,
      productId: product.id,
    }).then((aiContent) => {
      dispatch(
        RVersion.setDraftItem({ ...draftVersion, attributes: aiContent }),
      );
    });

    return () => {
      dispatch(RVersion.setDraftItem(null));
      dispatch(RVersion.setCurrentItem(null));
    };
  }, [dispatch, product, testStrategy]);

  const saveVersion = async (andPublish: boolean) => {
    if (!draftVersion) return;

    dispatch(RSpinner.show(true));

    // Try to create version with incrementing title suffixes if needed
    let attempt = 0;
    let createResult: Version | Error;

    do {
      // Create version title with suffix for attempts after the first one
      const title =
        attempt === 0
          ? draftVersion.title
          : `${draftVersion.title} ${attempt + 1}`;

      createResult = await createVersion({ ...draftVersion, title });

      // If successful or error other than 409 conflict, break the loop
      if (
        !isError(createResult) ||
        !(createResult instanceof ApiError) ||
        createResult.status !== 409
      )
        break;
    } while (attempt++ < 10);

    if (isError(createResult)) {
      dispatch(RSpinner.show(false));
      return toast.show(createResult);
    }

    dispatch(RVersion.setCurrentItem(createResult));
    if (andPublish) {
      const publishResult = await publishVersion(createResult.id);
      if (isError(publishResult)) {
        if (publishResult instanceof ApiError && publishResult.status === 426)
          return dispatch(RModal.open({ type: 'UpgradeRequiredModal' }));
        else toast.show(publishResult);
      }
    }

    dispatch(RTenant.setOnboarded(true));
    setTimeout(() => {
      history.push(
        `/products/${createResult.parent.id}/versions/${createResult.id}/overview`,
      );
    }, 500);
    dispatch(RSpinner.show(false));
  };

  return (
    <div className="version-view">
      <div className={'content-editor' + (draftVersion ? '' : ' disabled')}>
        <ContentEditor />
      </div>
      {!draftVersion && (
        <div className="overlay">
          <div className="inner">
            <h1>
              {capitalizeEveryFirstLetter(
                t('testFlow.step2.creatingTests.title'),
              )}
            </h1>
            <p>
              {capitalizeFirstLetter(
                t('testFlow.step2.creatingTests.description'),
              )}
            </p>
            <CreatingTestIcon />
          </div>
        </div>
      )}
      {draftVersion && (
        <footer className="action-bar">
          <PrimaryButton onClick={() => saveVersion(true)}>
            {capitalizeEveryFirstLetter(t('global.publish'))}
          </PrimaryButton>
          <PrimaryButton onClick={() => saveVersion(false)} variant="gray">
            {capitalizeFirstLetter(t('testFlow.step2.saveAsDraft'))}
          </PrimaryButton>
        </footer>
      )}
    </div>
  );
};
