import { createApi } from '.';

const api = createApi<
  {
    attributes: {
      type: 'array';
      description: 'The attribute definitions of the product the Template targets';
      items: {
        type: 'object';
        properties: {
          name: {
            type: 'string';
            description: 'The internal name of the attribute to query an `AttributeValueMap` with';
          };
          type: {
            type: 'string';
            enum: ['text', 'html', 'assets', 'richContent'];
            description: 'The type of attribute to interpret an `AttributeValueMap` with';
          };
          displayName: {
            type: 'string';
            description: 'A human readable name to display (e.g. "Title")';
          };
          sourceKey: {
            type: 'string';
            description: 'The name of the attribute in an external system (e.g. "Name")';
          };
          customizable: {
            type: 'boolean';
            description: 'If attribute can be overwritten by an experience/version';
          };
          validator: {
            type: 'object';
            description: 'An optional set of constraints on the content that can be used';
            properties: {
              min: {
                type: 'number';
                description: 'An optional minimum content length required';
              };
              max: {
                type: 'number';
                description: 'An optional maximum content length required';
              };
              regex: {
                type: 'string';
                description: 'Limitations on what text can be entered';
              };
            };
            additionalProperties: false;
          };
        };
        required: ['name', 'type', 'displayName', 'sourceKey', 'customizable'];
        additionalProperties: false;
      };
      default: [];
    };
  },
  []
>('/templates');

export type Template = typeof api.types.response;

/** An attribute definition on the template level */
export type TemplateAttribute = Template['attributes'][number];

/** The different types of attributes that can be created */
export type AttributeTypes = TemplateAttribute['type'];

/** A set of constraints on the content that can be used */
export type TemplateValidator = Exclude<
  TemplateAttribute['validator'],
  undefined
>;

/**
 * @param pid The product or page ID
 */
export const fetchTemplate = (
  pid: string,
  type: 'product' | 'page',
): ReturnType<typeof api.get> =>
  api.request<Template>(`/${type}-template/${pid}`);
