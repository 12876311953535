import { DigitalAssetsModal } from '@components/DigitalAssetsModal/DigitalAssetsModal';
import { Editor as EditorComp } from '@tinymce/tinymce-react';
import { ASSET_TYPES } from '@utils/files';
import { useEffect, useRef, useState } from 'react';
import type { Editor, Schema } from 'tinymce';

import './EditorBox.scss';

export const RichTextEditor: React.FC<{
  id: string;
  value: string | undefined;
  onChange: (str: string) => unknown;
  limit?: number;
  valid?: boolean;
}> = ({ value, onChange, valid = true }) => {
  const [initialValue, setInitialValue] = useState(value);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const editorRef = useRef<Editor>();
  const editorContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.getContent())
      editorRef.current.setContent(value || '');
  }, [value]);

  useEffect(() => {
    if (editorContainerRef.current) {
      if (valid) {
        editorContainerRef.current.classList.add('custom-editor-border');
        editorContainerRef.current.classList.remove(
          'custom-editor-border-limit',
        );
      } else {
        editorContainerRef.current.classList.remove('custom-editor-border');
        editorContainerRef.current.classList.add('custom-editor-border-limit');
      }
    }
  }, [valid]);

  return (
    <>
      <EditorComp
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={initialValue}
        onEditorChange={onChange}
        init={{
          height: 200,
          menubar: false,
          paste_data_images: false,
          elementpath: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'powerpaste',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist custom | ' +
            'removeformat | code',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          branding: false,
          extended_valid_elements:
            'style[type|media],link[type<text/css|rel<stylesheet|!href]',
          valid_children:
            '+body[style|link],+div[style|link],+section[style|link],+button[div]',
          setup: (editor) => {
            editor.on('init', () => {
              editorRef.current = editor;
              editorContainerRef.current = editor.getContainer();
              if (valid) {
                editorContainerRef.current.classList.add(
                  'custom-editor-border',
                );
              } else {
                editorContainerRef.current.classList.add(
                  'custom-editor-border-limit',
                );
              }
            });
          },
        }}
        onInit={(_, editor) => {
          editorRef.current = editor;
          editor.ui.registry.addButton('custom', {
            icon: 'image',
            onAction: () => setShowAssetModal(true),
          });
          // Allow the playsinline attribute on video elements
          editor.schema.elements['video'].attributes['playsinline'] = {};

          /** The name to rename <templates> to so they correctly get converted */
          const templateRenameTag = 'template-hc-temp';

          // Add "plugin to allow any custom HTML components"
          editor.on('BeforeSetContent', (event) => {
            // Ensure template children don't get converted to &nbsp;
            event.content = event.content.replace(
              /(<\/?)template( |>)/g,
              `$1${templateRenameTag}$2`,
            );

            const { content, target } = event;
            const { schema }: { schema: Schema } = target;

            // Parse the content to find custom elements
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            // Get all unique tags in the document
            const tags = new Set(
              Array.from(doc.querySelectorAll('*')).map((e) =>
                e.tagName.toLowerCase(),
              ),
            );

            // Get the default elements defined in the TinyMCE schema
            const defaultElements = Object.keys(schema.elements);

            // Filter out custom tags (non-standard HTML elements)
            const customTags = Array.from(tags).filter(
              (tag) => !defaultElements.includes(tag),
            );

            // Add custom elements to the schema
            for (const tag of customTags) schema.addCustomElements(tag);
          });
          /** Convert temporary {@link templateRenameTag} back */
          editor.on('GetContent', (event) => {
            event.content = event.content.replace(
              new RegExp(`(<\\/?)${templateRenameTag}( |>)`, 'g'),
              '$1template$2',
            );
          });

          // Set the initial content if different from current editor value
          if (value !== editor.getContent()) setInitialValue(value);
        }}
      />
      <DigitalAssetsModal
        showModal={showAssetModal}
        setShowModal={setShowAssetModal}
        onClose={() => setShowAssetModal(false)}
        setFinalFiles={(result) => {
          const editor = editorRef.current;
          setShowAssetModal(false);
          if (!editor) return;
          for (const file of result) {
            const type =
              ASSET_TYPES.find((a) => a.extension === file.format)?.type ||
              'image';
            const elementType = type === 'video' ? 'video' : 'img';
            const element = editor.dom.createHTML(elementType, {
              src: file.url,
              alt: file.altText,
              style: 'max-width: 100%',
              ...(type === 'video' && {
                poster: file.url.replace(/\.[^/.]+$/, '.jpg'),
                autoplay: 'true',
                muted: 'true',
                loop: 'true',
                playsInline: 'true',
              }),
            });
            editor.insertContent(element);
          }
        }}
        maxFiles={Number.MAX_SAFE_INTEGER}
      />
    </>
  );
};
