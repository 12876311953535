import fallbackImage from '@assets/illustrations/57_NoImage.svg';
import loadingImage from '@assets/illustrations/LoadingImage.svg';
import { isAssetVideo } from '@utils/digitalAssets';
import { AssetCache } from '../../../hooks/useAssets';

export const Asset: React.FC<
  React.HTMLAttributes<HTMLElement> & {
    assetId: string | undefined;
    assetCache: AssetCache;
  }
> = ({ assetId, assetCache, ...other }) => {
  // Loading state
  if (!assetId) return <img alt="Missing" />;
  const asset = assetCache[assetId];
  if (!asset) return <img alt="Empty or loading" src={loadingImage} />;
  // Rendering state
  if (isAssetVideo(asset)) return <video src={asset.url} {...other} />;
  else
    return (
      <img src={asset?.url || fallbackImage} alt={asset?.altText} {...other} />
    );
};
