import { listTestStrategies, type TestStrategy } from '@apis/aiService';
import { MultiToggle } from '@components/MultiToggle/MultiToggle';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { colorsByTestLevel } from '@components/TestTargets/TestTargets';
import { RTenant } from '@redux/slices/tenant';
import { RVersion } from '@redux/slices/version';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TestCard } from './TestCard';
import { TestCardLoading } from './TestCard/loading';

import './style.scss';

const levels = ['basic', 'advanced', 'expert'] as const;

export const SelectExperienceView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onboarded } = useSelector((state) => state.tenant);

  const levelTextsMap = Object.fromEntries(
    levels.map((level) => [
      capitalizeEveryFirstLetter(t('testFlow.step0.levels.' + level)),
      level,
    ]),
  );
  const levelTexts = Object.keys(levelTextsMap);

  const [allTests, setAllTests] =
    useState<{ [key in (typeof levels)[number]]: TestStrategy[] }>();
  const [selectedTestStrategy, setSelectedTestStrategy] =
    useState<TestStrategy>();
  const [selectedLevelText, setSelectedLevelText] = useState<
    (typeof levelTexts)[number]
  >(levelTexts[0]);
  const selectedLevel = levelTextsMap[selectedLevelText];
  const tests = allTests ? allTests[selectedLevel] : null;

  useEffect(() => {
    listTestStrategies().then(({ items: tests }) => {
      const testsByLevel = Object.fromEntries(
        levels.map((level): [(typeof levels)[number], TestStrategy[]] => {
          const testWithLevel = tests.filter((test) => test.level === level);
          return [level, testWithLevel];
        }),
      ) as { [key in (typeof levels)[number]]: TestStrategy[] };
      setAllTests(testsByLevel);
    });
  }, []);

  return (
    <div className="select-test-view">
      <MultiToggle
        options={levelTexts}
        selected={selectedLevelText}
        selectedColor={colorsByTestLevel[selectedLevel].primary}
        setSelected={(selected) => {
          setSelectedLevelText(selected);
          if (selected !== selectedLevelText) setSelectedTestStrategy(void 0);
        }}
      />
      <div className="test-list">
        {tests
          ? tests.map((test) => (
              <TestCard
                test={test}
                testLevel={selectedLevel}
                key={test.id}
                isSelected={test.id === selectedTestStrategy?.id}
                setSelected={setSelectedTestStrategy}
              />
            ))
          : Array(5)
              .fill(0)
              .map((_, i) => <TestCardLoading key={i} />)}
      </div>

      <div>
        <PrimaryButton
          className="next-button"
          disabled={!selectedTestStrategy}
          onClick={() => {
            if (selectedTestStrategy)
              dispatch(RVersion.setTestStrategy(selectedTestStrategy));
          }}
        >
          {capitalizeFirstLetter(t('global.next'))}
        </PrimaryButton>
      </div>
      {onboarded === false && (
        <Link
          className="skip"
          to={'/'}
          onClick={() => dispatch(RTenant.setOnboarded(true))}
        >
          {capitalizeFirstLetter(t('strategyBoard.skip_and_go_to_dashboard'))}
        </Link>
      )}
    </div>
  );
};
