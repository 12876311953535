import {
  getProduct,
  listProducts,
  listVariants,
  Product,
  productListConfig,
  Variant,
} from '@apis/products';
import { toast } from '@components/ToastNotification/ToastManager';
import { ListableActions, ListableState } from '@models/filter';
import { registerSideEffects } from '@redux/sideEffects';
import { AppThunk } from '@redux/store';
import { createSlice } from '@reduxjs/toolkit';
import { isError } from '@sentry/utils';
import { ensureError } from '@utils/ImproperError';

type ProductState = ListableState<Product, typeof productListConfig> & {
  currentItem: Product | null;
  variants: Record<string, Variant> | null;
};

const initialState: ProductState = {
  sortBy: 'relevanceScore',
  sortOrder: 'desc',
  filters: {
    status: undefined,
  },
  searchText: '',
  page: 0,
  items: null,
  totalItems: 0,
  selectedItems: [],
  currentItem: null,
  variants: null,
};

type ListActions = ListableActions<typeof productListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      Object.assign(state.filters, payload);
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: Product[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    setSelectedItems: (state, { payload }: { payload: Product[] }) => {
      state.selectedItems = payload;
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters;
      caseReducers.resetResults(state);
    },
    updateProductDetails: (
      state,
      {
        payload,
      }: {
        payload: Pick<Product, 'id'> & Partial<Product>;
      },
    ) => {
      const product = state.items?.find(({ id }) => id === payload.id);
      if (product) Object.assign(product, payload);
    },
    setCurrentItem: (state, { payload }: { payload: Product | null }) => {
      state.currentItem = payload;
    },
    setVariants: (
      state,
      { payload }: { payload: Record<string, Variant> | null },
    ) => {
      state.variants = payload;
    },
  },
});

const thunks = {
  loadProduct:
    (id: string): AppThunk =>
    (dispatch, getState) => {
      const { currentItem } = getState().products;
      // Already loaded
      if (currentItem?.id === id) return;

      // Reset while loading
      dispatch(actions.setCurrentItem(null));
      dispatch(actions.setVariants(null));

      // Load product and variants
      getProduct(id).then((product) => {
        if (product instanceof Error) return toast.show(product);
        else dispatch(actions.setCurrentItem(product));
      });
      listVariants(id).then(async (variants) => {
        if (variants instanceof Error) return toast.show(variants);

        const { items, totalCount } = variants;
        const entries = items.map((v) => [v.id, v]);
        dispatch(actions.setVariants(Object.fromEntries(entries)));
        let page = 1;
        while (
          items.length < totalCount &&
          getState().products.currentItem?.id === id
        ) {
          const result = await listVariants(id, page++);
          if (isError(result)) return toast.show(result);
          entries.push(...result.items.map((v) => [v.id, v]));
          dispatch(actions.setVariants(Object.fromEntries(entries)));
        }
      });
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

let listAbortController: AbortController | null = null;

registerSideEffects({
  name: 'Fetch next products',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
  ],
  execute: async ({ dispatch, state }) => {
    try {
      if (listAbortController) listAbortController.abort();
      const abortController = new AbortController();
      listAbortController = abortController;

      const productsResult = await listProducts(state.products);
      if (abortController.signal.aborted) return;
      if (isError(productsResult)) return toast.show(productsResult);

      const { items, totalCount } = productsResult;
      dispatch(RProduct.addItems(items));
      dispatch(RProduct.setItemCount(totalCount));
    } catch (err) {
      toast.show(ensureError(err));
      console.error(err);
      dispatch(RProduct.setErrorState());
    }
  },
});

export const RProduct = Object.assign(actions, thunks);

export default reducer;
