import { AttributeValueMap } from '@models/product';
import { clientWithToken } from '.';

export type TestStrategy = {
  id: string;
  type: 'test' | 'personalize';
  level: 'basic' | 'advanced' | 'expert';
  generationPrompts?: { [attribute: string]: string };
  attributes: string[];
  title: string;
  description: string | null;
};

export type AIGeneration = AttributeValueMap;

const basePath = '/intelligence';

/** Uses AI to generate version content for the testStrategy on the product */
const generate = async (request: {
  testId: string;
  productId: string;
}): Promise<AIGeneration> => {
  const searchParams = new URLSearchParams(request);
  type InternalAIGeneration = {
    [attribute: string]:
      | string
      | {
          altText: string;
          damUrl: string;
          shopUrl: string;
          position: number;
          assetId: string;
        }[];
  };
  const { data } = await clientWithToken.get<InternalAIGeneration>(
    `${basePath}/generate?${searchParams}`,
  );

  return Object.fromEntries(
    Object.entries(data).map(([n, v]): [string, string | string[]] => [
      n,
      Array.isArray(v) ? v.map((a) => a.assetId) : v,
    ]),
  );
};

export const listTestStrategies = async (): Promise<{
  items: TestStrategy[];
  totalCount: number;
}> => {
  const { data, headers } = await clientWithToken.get<TestStrategy[]>(
    `${basePath}/tests`,
  );
  const totalCount = Number(headers['x-total-count']);
  return { items: data, totalCount };
};

export const getTestStrategy = async (id: string): Promise<TestStrategy> => {
  const { data } = await clientWithToken.get<TestStrategy>(
    `${basePath}/tests/${id}`,
  );
  return data;
};

export const AIService = { generate };
