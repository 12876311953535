import { damListConfig, getAsset } from '@apis/digitalAssets';
import { FilterSidebar } from '@components/FilterSidebar/FilterSidebar';
import { toast } from '@components/ToastNotification/ToastManager';
import { DigitalAssetBrief } from '@models/digital-asset';
import { AllFilesType } from '@models/files';
import { ModalProps } from '@models/modal';
import { RAsset } from '@redux/slices/asset';
import { RModal } from '@redux/slices/modal';
import { ALL_TYPES_TO_EXTENSIONS_MAP, ASSET_TYPES } from '@utils/files';
import { ensureError } from '@utils/ImproperError';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCachedAsset } from '../../hooks/useAssets';
import { useDeepCompareMemoize } from '../../hooks/useDeepMemoize';
import DigitalAssetCard from '../../pages/digital-assets/+page/DigitalAssetCard';
import { UpdatedInfiniteScrolling } from '../InfiniteScrolling/UpdatedInfiniteScrolling';
import { ListingCard } from '../ListingCard/ListingCard';
import { LoadingCard } from '../LoadingCard/LoadingCard';
import useLegacyModal from '../ModalLayout/useModal';
import { FileUploadModal } from '../Modals/FileUploadModal/FileUploadModal';
import { HeaderFooterModalLayout } from '../Modals/HeaderFooterModalLayout/HeaderFooterModalLayout';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { SearchBar } from '../SearchBar/SearchBar';

import './DigitalAssetsModal.scss';

export const DigitalAssetsModal: React.FC<
  ModalProps & {
    /** Allows filtering by filetype of specific files */
    allowedFiles?: AllFilesType[];
    restrictToIds?: string[];
    maxFiles: number;
    setFinalFiles: (assets: DeepReadonly<DigitalAssetBrief[]>) => void;
  }
> = ({
  showModal,
  setShowModal,
  onClose,
  allowedFiles,
  restrictToIds,
  maxFiles,
  setFinalFiles,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState) => rootState.assets);
  const { openModal, closeModal, showModalId } = useLegacyModal();

  useEffect(() => {
    if (!showModal || showModalId !== 'dam-modal')
      dispatch(RAsset.resetResults());
  }, [showModal, showModalId, dispatch]);

  const stableAllowedFiles = useDeepCompareMemoize(allowedFiles);
  const stableRestrictToIds = useDeepCompareMemoize(restrictToIds);

  useEffect(() => {
    if (!stableRestrictToIds) {
      dispatch(
        RAsset.updateFilter({
          fileType: stableAllowedFiles
            ? stableAllowedFiles.map((v) => ALL_TYPES_TO_EXTENSIONS_MAP[v])
            : ASSET_TYPES.map((a) => a.extension),
        }),
      );
      dispatch(RAsset.setRestrictToIds(null));
    } else {
      // Set restrictToIds flag to prevent normal API fetching
      dispatch(RAsset.setRestrictToIds(stableRestrictToIds));

      // Reset and add the specific assets
      dispatch(RAsset.setErrorState());
      Promise.all(
        stableRestrictToIds.map((id) => getCachedAsset(id) ?? getAsset(id)),
      )
        .then((assets) => {
          dispatch(RAsset.addItems(assets));
          dispatch(RAsset.setItemCount(assets.length));
        })
        .catch((error) => toast.show(ensureError(error)));
    }
  }, [stableAllowedFiles, stableRestrictToIds, dispatch]);

  const fileUploadModal = {
    modalId: 'fileuploadModalId',
    openModal: () => {
      openModal('fileuploadModalId');
    },
  };

  const onModalClose = () => {
    dispatch(RAsset.resetResults());
    onClose();
  };

  const onPressDone = () => {
    setFinalFiles(
      Object.values(state.selectedAssets).sort(
        (a, b) => a.selectionOrder - b.selectionOrder,
      ),
    );
    onModalClose();
  };

  const handleNext = () => {
    dispatch(RAsset.incrementPage());
  };

  const handleCardClick = (asset: DeepReadonly<DigitalAssetBrief>) => {
    if (
      state.selectedAssets[asset.id] ||
      Object.keys(state.selectedAssets).length < maxFiles
    ) {
      dispatch(RAsset.toggleSelected(asset));
    } else {
      dispatch(
        RModal.open({
          type: 'TypeA',
          heading: t('global.limitExceeded'),
          supportingText: `${capitalizeFirstLetter(
            t('digitalAssets.canNotSelectMoreImagesStmt1', {
              count: maxFiles,
              image:
                maxFiles > 1 ? t('global.image_plural') : t('global.image'),
            }),
          )} ${capitalizeFirstLetter(
            t('digitalAssets.canNotSelectMoreImagesStmt2'),
          )}`,
        }),
      );
    }
  };

  return (
    <>
      <HeaderFooterModalLayout
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onModalClose}
        header={
          <header className="digitalAssetsModal__header">
            <h2 className="digitalAssetsModal__header--title">
              {capitalizeFirstLetter(t('digitalAssets.title'))}
            </h2>
          </header>
        }
        body={
          <div className="digitalAssetsModal__main">
            <FilterSidebar
              filterNamespace={'digitalAssets'}
              listableConfiguration={damListConfig}
              listableState={state}
              listableAction={RAsset}
              fixedFilters={!Array.isArray(allowedFiles) ? [] : ['fileType']}
            />

            <div
              className="digitalAssetsModal__container"
              id="damScrollableDiv"
            >
              <div className="digitalAssetsModal__container--searchBar">
                <SearchBar
                  id="modal-digital-asset-search-bar"
                  value={state.searchText}
                  placeholder={`${capitalizeFirstLetter(
                    t('digitalAssets.searchPlaceholder'),
                  )}...`}
                  enterkeyHandler={() => {
                    // Do nothing
                  }}
                  updateSearch={(value) =>
                    dispatch(RAsset.setSearchText(value))
                  }
                  onClearHandler={() => dispatch(RAsset.setSearchText(''))}
                  count={state.totalItems}
                  showShowingResult
                  title={t('global.results')}
                />
              </div>
              <UpdatedInfiniteScrolling
                className="digitalAssetsModal__card-list"
                dataLength={state.items?.length ?? 0}
                endMessage={t('digitalAssets.noMoreDigitalAssets')}
                hasMore={!!state.items && state.items.length < state.totalItems}
                loader={<LoadingCard />}
                scrollableTarget={'damScrollableDiv'}
                isLoading={
                  !state.items || state.totalItems > state.items.length
                }
                numberOfLoaders={3}
                next={handleNext}
              >
                <>
                  <ListingCard
                    id="modal-digital-asset-create-card"
                    isCreateCard
                    module="digitalAsset"
                    createButtonText={t('digitalAssets.createNew')}
                    onCreateClick={fileUploadModal.openModal}
                  />
                  {state.items?.map((asset) => (
                    <DigitalAssetCard
                      id={`modal-digital-asset-card-${asset.id}`}
                      key={asset.id}
                      asset={asset}
                      isSelected={!!state.selectedAssets[asset.id]}
                      showDeleteIcon
                      showEditOption
                      disableRedirect
                      onChange={() => handleCardClick(asset)}
                    />
                  ))}
                </>
              </UpdatedInfiniteScrolling>
            </div>
          </div>
        }
        footer={
          <footer className="digitalAssetsModal__footer">
            <PrimaryButton
              disabled={Object.keys(state.selectedAssets).length === 0}
              onClick={
                Object.keys(state.selectedAssets).length === 0
                  ? () => null
                  : onPressDone
              }
              variant="green"
              size="small"
            >
              {capitalizeFirstLetter(t('global.done'))}
            </PrimaryButton>
          </footer>
        }
      />

      {/** Only allow uploading if not limited to specific IDs */}
      {(allowedFiles == null || Array.isArray(allowedFiles)) && (
        <FileUploadModal
          showModal={fileUploadModal.modalId === showModalId}
          setShowModal={closeModal}
          onClose={closeModal}
          allowedFiles={allowedFiles ?? ASSET_TYPES.map((a) => a.mime)}
          onUpload={() => dispatch(RAsset.resetResults())}
          sendConfig
        />
      )}
    </>
  );
};
