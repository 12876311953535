import { PredefinedDateOption } from '@components/DateRangePicker/DateRangePicker';
import { DATE_FORMAT } from '@constants/dateFormats';
import i18n from 'i18next';
import moment from 'moment';

type DateFormatType = keyof typeof DATE_FORMAT;

/**
 * Formats a date according to the specified format
 * @param date date to be formatted
 * @param format desired date format from DATE_FORMAT constant
 * @returns formatted date string
 */
export const formatDate = (
  date: Date | string | number,
  format: DateFormatType = 'format1',
): string => {
  const dateFormat = DATE_FORMAT[format];
  return moment(date).format(dateFormat).toString();
};

/**
 * Get a date relative to the input date
 * @param date input date
 * @param quantity number of units to add/subtract
 * @param operation 'add' or 'subtract'
 * @param entity 'days', 'weeks', 'months', or 'years'
 */
export const getDate = (
  date: Date | string | number,
  quantity: number,
  operation: 'add' | 'subtract',
  entity: 'days' | 'weeks' | 'months' | 'years' = 'days',
): moment.Moment => {
  const momentDate = moment.utc(date);

  if (operation === 'add') {
    return entity === 'days'
      ? momentDate.add(quantity, entity).startOf('day')
      : momentDate.add(quantity, entity);
  } else {
    return entity === 'days'
      ? momentDate.subtract(quantity, entity).startOf('day')
      : momentDate.subtract(quantity, entity);
  }
};

/**
 * Get the start date for a predefined date range
 * @param value predefined date range option
 */
export const setDate = (
  value: PredefinedDateOption | string,
): moment.Moment => {
  switch (value) {
    case 'Today':
      return moment.utc(formatDate(moment().toDate()));

    case 'Yesterday':
      return moment.utc(getDate(moment().toDate(), 1, 'subtract'));

    case 'Last 7 days':
      return moment.utc(getDate(moment().toDate(), 6, 'subtract'));

    case 'Last 14 days':
      return moment.utc(getDate(moment().toDate(), 13, 'subtract'));

    case 'Last 30 days':
      return moment.utc(getDate(moment().toDate(), 29, 'subtract'));

    default:
      return moment.utc(getDate(moment().toDate(), 29, 'subtract'));
  }
};

export const timeSince = (seconds: number): string => {
  let interval = seconds / 31536000;
  let currentTime = '';
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 31536000;
    currentTime = Math.floor(interval) + 'yr';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 2592000;
    currentTime += Math.floor(interval) + 'months ';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 86400;
    currentTime += Math.floor(interval) + 'd ';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 3600;
    currentTime += Math.floor(interval) + 'h ';
  }
  interval = seconds / 60;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 60;
    currentTime += Math.floor(interval) + 'm ';
  }
  if (seconds > 1) {
    currentTime += Math.floor(seconds) + 's';
  }
  return currentTime;
};

export const calculateDayDifferenceTimeFromPercentage = (
  percentage: number,
): string => timeSince((percentage * 86400) / 100);

/**
 * Get human-readable time difference message
 * @returns Last updated message string
 */
export const getTimeDifference = (time: number): string => {
  const minute = Math.floor(time / 60),
    hour = Math.ceil(time / 3600),
    day = Math.ceil(time / 86400);
  let message = '';
  if (time < 60) {
    message = `${time} ${
      time > 1
        ? i18n.t('digitalAssets.second_plural')
        : i18n.t('digitalAssets.second')
    }`;
  } else if (time < 3600) {
    message = `${minute} ${
      time > 60
        ? i18n.t('digitalAssets.minute_plural')
        : i18n.t('digitalAssets.minute')
    }`;
  } else if (time < 86400) {
    message = `${hour} ${
      time > 3600
        ? i18n.t('digitalAssets.hour_plural')
        : i18n.t('digitalAssets.hour')
    }`;
  } else if (time < new Date().getDate() * 86400) {
    message = `${day} ${
      day > 1 ? i18n.t('context.days') : i18n.t('digitalAssets.day')
    }`;
  }
  return message;
};

export const MILLISECONDS_IN_A_SECOND = 1000;

export const timeDifferenceInSeconds = (value: number): number =>
  Math.floor(Math.abs(Date.now() - value) / MILLISECONDS_IN_A_SECOND);

export const timeDifferenceFromStartOfDay = (value: number): number =>
  Math.floor(
    Math.abs(moment().startOf('day').valueOf() - value) /
      MILLISECONDS_IN_A_SECOND,
  );

/**
 * Convert milliseconds to YYYY-MM-DD format
 */
export const getStringDate = (milliSeconds = NaN): string => {
  const formattedDate = new Date(milliSeconds);
  return `${formattedDate.getFullYear()}-${String(
    formattedDate.getMonth() + 1,
  ).padStart(2, '0')}-${String(formattedDate.getDate()).padStart(2, '0')}`;
};

/**
 * Converts a date string in YYYY-MM-DD format to a Date object in local timezone
 */
export const getDateInTimezone = (dateString: string): Date => {
  const dateParts = dateString.split('-');

  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);

  return new Date(year, month, day);
};
