import {
  ListablePayload,
  ListableState,
  ListConfiguration,
} from '@models/filter';
import { createApi } from '.';

export namespace ExperimentApi {
  export namespace List {
    export type State = ListableState<Experiment, typeof experimentListConfig>;
    export type Query = ListablePayload<typeof experimentListConfig>;
  }
}

export const experimentListConfig = {
  sort: [
    { sortBy: 'name', sortOrder: 'asc' },
    { sortBy: 'name', sortOrder: 'desc' },
    { sortBy: 'lastUsedAt', sortOrder: 'desc' },
    { sortBy: 'lastUsedAt', sortOrder: 'asc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
    { sortBy: 'createdAt', sortOrder: 'desc' },
    { sortBy: 'createdAt', sortOrder: 'asc' },
  ],
  filters: [],
} as const satisfies ListConfiguration;

const api = createApi<
  {
    name: { type: 'string' };
    versions: {
      type: 'array';
      items: {
        type: 'object';
        properties: {
          type: { enum: ['page', 'product'] };
          parentId: { type: 'string' };
          id: { type: 'string' };
        };
        required: ['type', 'parentId', 'id'];
        additionalProperties: false;
      };
      default: [];
    };
    lastUsedAt: { type: ['number', 'null']; default: null; readOnly: true };
    tags: {
      type: 'array';
      items: { type: 'string' };
      default: [];
    };
    tagsInternal: {
      type: 'array';
      items: { type: 'string' };
      default: [];
    };
  },
  ['name']
>('/experiments');

export type Experiment = typeof api.types.response;

export const getExperiment = api.get;

export const listExperiments = api.list;

export const createExperiment = api.post;

export const deleteExperiment = api.delete;

export const tagVersionToExperiment = (
  experimentId: string,
  version: Experiment['versions'][number],
) =>
  api.request<Experiment>(`/${experimentId}/versions`, {
    method: 'POST',
    body: version,
  });

export const removeVersionToExperiment = (
  experimentId: string,
  version: Experiment['versions'][number],
) =>
  api.request<Experiment>(
    `/${experimentId}/versions?${new URLSearchParams(version)}`,
    {
      method: 'DELETE',
    },
  );

export const updateVersionExperimentTags = ({
  experimentIds,
  prevExperimentIds,
  ...versionTag
}: Experiment['versions'][number] & {
  experimentIds: string[];
  prevExperimentIds: string[];
}) => {
  const promises: Promise<Experiment | Error>[] = [];
  for (const id of prevExperimentIds) {
    if (!experimentIds.includes(id))
      promises.push(removeVersionToExperiment(id, versionTag));
  }
  for (const id of experimentIds) {
    if (!prevExperimentIds.includes(id))
      promises.push(tagVersionToExperiment(id, versionTag));
  }
  return Promise.all(promises);
};
