import { PageWithAttributes } from '@apis/pages';
import { ProductWithAttributes } from '@apis/products';
import { Version } from '@apis/versions';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Returns the base version of the redux's `version.currentItem`
 *
 * @example
 * ```ts
 * const baseVersion = useBaseVersion();
 *
 * return (
 *   <div>
 *     <h1>{baseVersion?.title}</h1>
 *     <p>{baseVersion?.attributes?.['description']}</p>
 *   </div>
 * );
 * ```
 */
export const useBaseVersion = (): DeepReadonly<
  | ProductWithAttributes
  | (PageWithAttributes & {
      variantOverrides?: undefined;
      defaultVariantId?: undefined;
    })
> | null => {
  const { currentItem: version } = useSelector((s) => s.versions);
  const { currentItem: product, variants } = useSelector((s) => s.products);
  const { currentItem: page } = useSelector((s) => s.pages);

  return useMemo(() => {
    if (!version) return null;
    else if (version.parent.type === 'page') return page;
    else {
      if (!(product && variants)) return null;

      const attributes = structuredClone(
        variants[product.defaultVariantId].attributes,
      );
      const variantOverrides = Object.entries(variants).reduce(
        (overrides, [id, variant]) => {
          overrides[id] = variant.attributes;
          return overrides;
        },
        {} as Version['variantOverrides'],
      );
      return { ...product, attributes, variantOverrides };
    }
  }, [page, product, version, variants]);
};
