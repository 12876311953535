import { AttributeTypes, TemplateAttribute } from '@apis/template';
import { AnyAttributeValue } from '@models/product';
import { AttributeValueError, isValidAttributeValue } from '@utils/template';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAssets } from '../../../hooks/useAssets';
import { useBaseVersion } from '../../../hooks/useBaseVersion';
import { Asset } from '../Asset/Asset';

import { ReactComponent as HTMLIcon } from '@assets/icons/HTMLIcon.svg';
import { ReactComponent as RCHorseIcon } from '@assets/icons/RCHorse.svg';
import { UrlIcon } from '@components/Icons/UrlIcon';

import { Variant } from '@apis/products';
import './AttributeView.scss';

type AttributeView<T extends AnyAttributeValue> = (
  value: T | undefined,
) => React.ReactNode;

export const getRichContentIcon = (rc: string) => {
  if (rc.startsWith('data:text/html,')) return <HTMLIcon />;
  else if (rc.startsWith('data:text/uri-list,')) return <UrlIcon />;
  else return <RCHorseIcon />;
};

// Assets component extracted to avoid conditional hook rendering
const AssetsViewer = ({ assetIds }: { assetIds: string[] | undefined }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { assetCache, cacheAssets } = useAssets();

  useEffect(
    () => assetIds && cacheAssets(...assetIds),
    [cacheAssets, assetIds],
  );

  return (
    <div className="gallery">
      {assetIds && assetIds.length > 0 && (
        <>
          <Asset
            assetId={assetIds?.at(selectedIndex)}
            assetCache={assetCache}
            className="hero"
          />
          {assetIds && assetIds.length > 1 && (
            <div className="thumbs">
              {assetIds.map((assetId, i) => (
                <Asset
                  assetId={assetId}
                  assetCache={assetCache}
                  aria-selected={i === selectedIndex}
                  onClick={() => setSelectedIndex(i)}
                  key={i}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const fieldInputs = {
  text: ((value) => (
    <p
      className="text-field-view"
      dangerouslySetInnerHTML={{ __html: value || '' }}
    />
  )) satisfies AttributeView<string>,
  html: ((value) => (
    <p
      className="text-field-view"
      dangerouslySetInnerHTML={{ __html: value || '' }}
    />
  )) satisfies AttributeView<string>,
  richContent: ((richContent) => (
    <div className="rich-content-list">
      {richContent?.map((rc) => {
        return (
          <div className="rich-content-item" key={rc}>
            {getRichContentIcon(rc)}
          </div>
        );
      })}
    </div>
  )) satisfies AttributeView<string[]>,
  assets: ((assetIds) => (
    <AssetsViewer assetIds={assetIds} />
  )) satisfies AttributeView<string[]>,
} satisfies {
  [type in AttributeTypes]: AttributeView<string> | AttributeView<string[]>;
};

/** An read-only view for a version of a product attribute */
export const AttributeView = ({
  attribute,
  type,
}: {
  attribute: TemplateAttribute;
  type: 'base' | 'version';
}) => {
  const { draftItem } = useSelector((s) => s.versions);
  const base = useBaseVersion();
  /** The type of gallery used (independent for non-products) */
  const galleryType = useSelector(
    (s) => s.products.currentItem?.galleryType || 'independent',
  );
  /** The variants for the base (empty array for non-products) */
  const variants = Object.values(useSelector((s) => s.products.variants ?? {}));
  const options = useSelector((s) => s.products.currentItem?.options ?? []);
  const activeOptions = options.filter((o) => o.isActive);

  // Create a normalized variant key from active option values
  const getVariantKey = (variant: Variant) => {
    // If all options are active, just use the variant.id
    if (options.length === activeOptions.length) {
      return variant.id;
    }

    // Otherwise generate a custom key based on active options only
    return activeOptions
      .map(
        (option) =>
          variant.optionValues[option.name]
            ?.toLowerCase()
            .replaceAll(' ', '_') ?? '',
      )
      .filter(Boolean) // Remove empty values
      .join('_');
  };

  // Create a representation of the variant that only includes active options
  const getVariantOptionString = useCallback(
    (variant: Variant) =>
      activeOptions
        .map((option) => variant.optionValues[option.name] || '')
        .join(' - '),
    [activeOptions],
  );

  // Process variants to remove duplicates when only a subset of options is active
  const processedVariants = useMemo(() => {
    // If all options are active, no need to deduplicate - but still sort alphabetically
    if (options.length === activeOptions.length) {
      return [...variants].sort((a, b) => {
        for (const option of activeOptions) {
          const aValue = a.optionValues[option.name] || '';
          const bValue = b.optionValues[option.name] || '';
          if (aValue !== bValue) return aValue.localeCompare(bValue);
        }
        return 0;
      });
    }

    // Keep track of rendered option combinations to avoid duplicates
    const seenOptionCombinations = new Set<string>();
    const dedupedVariants: Variant[] = [];

    // First sort the variants to ensure consistent selection
    const sortedVariants = [...variants].sort((a, b) => {
      for (const option of activeOptions) {
        const aValue = a.optionValues[option.name] || '';
        const bValue = b.optionValues[option.name] || '';
        if (aValue !== bValue) return aValue.localeCompare(bValue);
      }
      return 0;
    });

    // Then filter to keep only unique combinations of active options
    for (const variant of sortedVariants) {
      // Skip variants that don't have all active options
      if (
        !activeOptions.every(
          (option) =>
            variant.optionValues &&
            variant.optionValues[option.name] !== undefined,
        )
      ) {
        continue;
      }

      // Create a key that represents this combination of active options
      const optionCombination = getVariantOptionString(variant);

      // Only add this variant if we haven't seen this combination before
      if (!seenOptionCombinations.has(optionCombination)) {
        seenOptionCombinations.add(optionCombination);
        dedupedVariants.push(variant);
      }
    }

    return dedupedVariants;
  }, [getVariantOptionString, variants, options, activeOptions]);

  const value =
    type === 'version'
      ? draftItem?.attributes[attribute.name]
      : base?.attributes[attribute.name];

  if (value !== undefined && !isValidAttributeValue(attribute, value))
    throw new AttributeValueError(attribute, value);

  // Use a separate renderer component for the asset attribute to avoid conditional hook rendering
  const renderAttributeValue = (
    attributeValue: (string & string[]) | undefined,
  ) => fieldInputs[attribute.type](attributeValue);

  return (
    <div className="attribute-view">
      <h5>{capitalizeEveryFirstLetter(attribute.displayName)}</h5>
      {attribute.type !== 'assets' ||
      variants.length < 2 ||
      galleryType === 'dependent'
        ? // Render normal attribute view for non-assets or only a single gallery
          renderAttributeValue(value)
        : // Render gallery view for products with multiple galleries
          processedVariants.map((variant) => {
            // Generate the variant key based on active options
            const variantKey = getVariantKey(variant);

            // Get the option combination label
            const optionLabel = getVariantOptionString(variant);

            // Get the attribute value using the key or fallback to variant.id for backward compatibility
            const attrValue = (
              type === 'version'
                ? draftItem?.variantOverrides[variantKey]?.[attribute.name] ??
                  draftItem?.variantOverrides[variant.id]?.[attribute.name] ??
                  []
                : base?.variantOverrides?.[variantKey]?.[attribute.name] ??
                  base?.variantOverrides?.[variant.id]?.[attribute.name] ??
                  []
            ) as string & string[];

            return (
              <div key={variant.id} className="variant-gallery">
                <h6>{optionLabel}</h6>
                {renderAttributeValue(attrValue)}
              </div>
            );
          })}
    </div>
  );
};
