import {
  fetchRichContent,
  moveRichContentTaskForReview,
  moveRichContentTaskToComplete,
  patchSelectedRichContentData,
  publishRichContent,
  startRichContentReview,
} from '@apis/richContent';
import { AddButtonWithSeparator } from '@components/AddButtonWithSeparator/AddButtonWithSeparator';
import useLegacyModal from '@components/ModalLayout/useModal';
import { PageHeading } from '@components/PageHeading/PageHeading';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { toast } from '@components/ToastNotification/ToastManager';
import { WorkflowOverviewHeader } from '@components/WorkflowHeader/WorkflowHeader';
import { WorkflowHeading } from '@components/WorkflowHeading/WorkflowHeading';
import { ROLES_PERMISSIONS } from '@constants/permissions';
import { ParamTypes } from '@models/context';
import { IPermission, ITemplate } from '@models/rich-content';
import { RRichContent } from '@redux/slices/richContent';
import { RSpinner } from '@redux/slices/spinner';
import { isError } from '@utils/ImproperError';
import { Mixpanel } from '@utils/mixpanel';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AddSectionList from '../../+page/AddSectionList';
import { sections } from '../../+page/defaultData';
import AddSectionModal from '../../+page/RichContentAddSelectionModal';
import { SectionFactory } from './SectionFactory';

import './style.scss';

const defaultPermissions = {
  AUTHOR_CONTENT: false,
  VISUAL_ASSETS: false,
  REVIEW: false,
};

const addSectionModalId = 'rich-content-section-selection-modal';

export const PageComponent: React.FC = () => {
  const [permissions, setPermissions] =
    useState<IPermission>(defaultPermissions);
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const { id } = useParams<ParamTypes>();
  const [templateData, setTemplateData] = useState<ITemplate[]>([]);
  const [addSectionIndex, setAddSectionIndex] = useState(0);
  const [taskAssigneeData, setTaskAssigneeData] = useState<any>();
  const userPermissions = useSelector((state) => state.currentUser.permissions);
  const { t } = useTranslation();
  const payload = {
    pageNumber: 1,
    pageSize: 20,
    ids: [id],
  };
  const richContentData = useSelector(
    (state) => state.richContent.selectedRichContent,
  );
  const loggedInUserId = useSelector((state) => state.currentUser?.data?.id);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (richContentData && richContentData.taskList && userPermissions) {
      const taskData: any = {};
      const permissionData: any = {};
      richContentData?.taskList?.forEach((original) => {
        const element = { ...original };
        taskData[element.taskType] = element;
        if (element.assignee !== loggedInUserId) return;
        if (element.taskType === 'AUTHOR_CONTENT') {
          if (element.status === 'TO_DO' || element.status === 'IN_PROGRESS')
            permissionData.AUTHOR_CONTENT = true;
        } else if (
          element.taskType === 'REVIEW' ||
          element.taskType === 'REVIEW_VISUAL_ASSET' ||
          element.taskType === 'REVIEW_CONTENT'
        ) {
          permissionData.REVIEW = true;
        } else if (element.taskType === 'VISUAL_ASSETS') {
          if (element.status === 'TO_DO' || element.status === 'IN_PROGRESS')
            permissionData.VISUAL_ASSETS = true;
        }
      });
      if (userPermissions) {
        const createRichContent = userPermissions?.some(
          (permission: any) =>
            permission.name === ROLES_PERMISSIONS.CREATE_RICH_CONTENT,
        );
        const updateRichContent = userPermissions?.some(
          (permission: any) =>
            permission.name === ROLES_PERMISSIONS.UPDATE_RICH_CONTENT,
        );

        if (createRichContent && updateRichContent) {
          permissionData.AUTHOR_CONTENT = true;
          permissionData.VISUAL_ASSETS = true;
          permissionData.REVIEW = true;
        }
      }

      setPermissions({ ...permissionData });
      setTaskAssigneeData(taskData);
    }
  }, [richContentData, userPermissions]);

  useEffect(() => {
    if (
      taskAssigneeData?.REVIEW?.status === 'TO_DO' &&
      process.env.REACT_APP_WORKFLOW === 'ON'
    ) {
      startRichContentReview(id, taskAssigneeData['REVIEW']?.id).then(
        async (result) => {
          if (isError(result)) toast.show(result);
          else {
            const result = await fetchRichContent(payload);
            if (isError(result)) toast.show(result);
            else dispatch(RRichContent.SET_SELECTED(result.items[0]));
          }
        },
      );
    }
  }, [taskAssigneeData]);

  useEffect(() => {
    if (!richContentData) {
      fetchRichContent(payload)
        .then((result) => {
          if (isError(result)) return toast.show(result);
          const [richContent] = result.items;
          dispatch(RRichContent.SET_SELECTED(richContent));
          setTemplateData(
            structuredClone(richContent.contentList) as DeepWritable<
              typeof richContent.contentList
            >,
          );
        })
        .catch(console.error);
    } else {
      setTemplateData(
        structuredClone(richContentData.contentList) as DeepWritable<
          typeof richContentData.contentList
        >,
      );
    }
  }, []);

  const submitTemplateData = async () => {
    // Send mix panel event
    Mixpanel.track('rich_content_operation', {
      eventName: 'Save rich content template data',
      kpi: 'Usage Grows',
      propertyType: 'event_property',
      fieldsUpdated: templateData,
      id: id,
    });

    dispatch(RSpinner.show(true));
    try {
      const patchResult = await patchSelectedRichContentData({
        richContentId: id,
        data: { contentList: templateData },
        updateAttributes: 'AUTHOR_CONTENT',
      });
      if (isError(patchResult)) toast.show(patchResult);
      const responseFromRichContentUpdate = await patchSelectedRichContentData({
        richContentId: id,
        data: { contentList: templateData },
        updateAttributes: 'VISUAL_ASSETS',
      });
      if (isError(responseFromRichContentUpdate))
        return toast.show(responseFromRichContentUpdate);

      dispatch(
        RRichContent.SET_SELECTED_CONTENT_LIST(structuredClone(templateData)),
      );

      const taskVisualAsset = responseFromRichContentUpdate.taskList.find(
        (x) => x.taskType === 'VISUAL_ASSETS',
      )!;
      const taskAuthorContent = responseFromRichContentUpdate.taskList.find(
        (x) => x.taskType === 'AUTHOR_CONTENT',
      )!;
      if (process.env.REACT_APP_WORKFLOW === 'ON') {
        if (taskAssigneeData?.AUTHOR_CONTENT?.status !== 'COMPLETED') {
          const result = await moveRichContentTaskForReview(
            id,
            taskAuthorContent.id!,
          );
          if (isError(result)) toast.show(result);
        }
        if (taskAssigneeData?.VISUAL_ASSETS?.status !== 'COMPLETED') {
          const result = await moveRichContentTaskForReview(
            id,
            taskVisualAsset.id!,
          );
          if (isError(result)) toast.show(result);
        }
      }
      if (taskAssigneeData?.REVIEW?.status === 'COMPLETED') {
        const result = await publishRichContent(id);
        if (isError(result)) toast.show(result);
      }

      if (process.env.REACT_APP_WORKFLOW === 'OFF') {
        const result = await moveRichContentTaskToComplete(id);
        if (isError(result)) toast.show(result);
      }
      const result = await fetchRichContent({
        pageNumber: 1,
        pageSize: 20,
        ids: [id],
      });
      if (isError(result)) toast.show(result);
      else dispatch(RRichContent.SET_SELECTED(result.items[0]));

      dispatch(RSpinner.show(false));
      history.push(`/rich-contents/${id}`);
    } catch (error: any) {
      const { errors } = error;
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    }
  };

  const addNewSection = (sectionName: keyof typeof sections) => {
    /** Cloned section from {@link sections} so we can modify it*/
    const { preview: _preview, ...section } = sections[sectionName];
    /** Cloned template data to update */
    const newTemplateData = [...templateData];
    // Insert section at index
    newTemplateData.splice(addSectionIndex, 0, {
      ...structuredClone(section),
      sectionName,
      id: crypto.randomUUID(),
    });
    // Recompute order
    newTemplateData.forEach((section, index) => (section.order = index + 1));
    setTemplateData(newTemplateData);
    closeModal();
  };

  const openAddNewElement = (index: number) => {
    if (!permissions.AUTHOR_CONTENT && !permissions.VISUAL_ASSETS) return;
    setAddSectionIndex(index);
    openModal(addSectionModalId);
  };

  const moveSection = (position: 'up' | 'down', index: number) => {
    let data;
    if (position === 'up') {
      data = templateData[index];
      templateData[index] = {
        ...templateData[index - 1],
        order: data.order,
      };
      templateData[index - 1] = {
        ...data,
        order: templateData[index - 1].order,
      };
    } else if (position === 'down') {
      data = templateData[index];
      templateData[index] = { ...templateData[index + 1], order: data.order };
      templateData[index + 1] = {
        ...data,
        order: templateData[index + 1].order,
      };
    } else {
      const finalTemplates = templateData.filter((_, key) => key !== index);
      finalTemplates.forEach((_, index) => {
        finalTemplates[index].order = index + 1;
      });
      setTemplateData([...finalTemplates]);
      return;
    }
    setTemplateData([...templateData]);
  };

  const handleChange = (
    value: string,
    field: string,
    index: string,
    isAsset = false,
    assetIds = [],
  ) => {
    const data = JSON.parse(JSON.stringify(templateData));
    if (isAsset) {
      data[index].attributes[field].assetIds = assetIds;
    }
    data[index].attributes[field].standardText = value;
    setTemplateData([...data]);

    Mixpanel.track('rich_content_operation', {
      eventName: 'Update rich content',
      kpi: 'Takes advantage of feature',
      propertyType: 'event_property',
    });
  };

  return (
    <>
      <PageHeading title={capitalizeFirstLetter(t('rich_content.title'))} />
      {richContentData && (
        <div className="RichContentTemplate">
          <WorkflowOverviewHeader
            centerSection={
              <div className="RichContentTemplate__header">
                <WorkflowHeading
                  heading={`${capitalizeFirstLetter(t('rich_content.title'))} ${
                    richContentData?.title
                  }`}
                  subHeading={capitalizeFirstLetter(
                    t('rich_content.polulate_data'),
                  )}
                />
              </div>
            }
            backButtonLink={`/rich-contents/${id}`}
            centered
          />
          <div className="RichContentTemplate__main">
            {templateData.length ? (
              <>
                {(permissions?.AUTHOR_CONTENT ||
                  permissions?.VISUAL_ASSETS) && (
                  <AddButtonWithSeparator
                    className="mt-1 mb-1"
                    onClick={() => openAddNewElement(0)}
                  />
                )}
                {templateData.map((template: ITemplate, index: number) => (
                  <Fragment key={template.id}>
                    <SectionFactory
                      key={template.id}
                      permissions={permissions}
                      sectionOrder={index}
                      section={template}
                      totalSections={templateData.length}
                      moveSection={moveSection}
                      handleChange={handleChange}
                    />
                    {(permissions?.AUTHOR_CONTENT ||
                      permissions?.VISUAL_ASSETS) && (
                      <AddButtonWithSeparator
                        className="mt-1 mb-1"
                        onClick={() => openAddNewElement(index + 1)}
                      />
                    )}
                  </Fragment>
                ))}
              </>
            ) : (
              <AddSectionList addNewSection={addNewSection} />
            )}
            <AddSectionModal
              showModal={addSectionModalId === showModalId}
              setShowModal={closeModal}
              onClose={closeModal}
              addNewSection={addNewSection}
            />
          </div>
          {taskAssigneeData && permissions && templateData?.length > 0 && (
            <div className="RichContentTemplate-footer">
              <div />
              <div>
                <PrimaryButton onClick={submitTemplateData}>
                  {capitalizeFirstLetter(t('global.save'))}
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
