import { ENDPOINTS } from '@constants/endpoints';
import {
  ContextDetailsType,
  IContext,
  ILocation,
  IUpdateContextBody,
} from '@models/context';
import { IUtm, IUtmUpdateBody, IUTMUsageUpdateBody } from '@models/utm';
import { ensureError, isError } from '@utils/ImproperError';
import { clientWithToken, PAGE_SIZE } from '.';

const SORT_BY = 'updatedAt';
const CONTEXT_SORT_ORDER = 'DESC';

export const listContexts = async ({
  searchText,
  page,
  status = [],
}: {
  searchText: string;
  page: number;
  status?: ReadonlyArray<'DRAFT' | 'NEW' | 'UPDATED'>;
}): Promise<{ content: IContext[]; totalElements: number } | Error> => {
  const response = await clientWithToken
    .post<{
      body: { content: IContext[]; totalElements: number };
    }>(ENDPOINTS.context.contextList, {
      contextName: searchText,
      sortBy: SORT_BY,
      sortOrder: CONTEXT_SORT_ORDER,
      pageNumber: page,
      pageSize: PAGE_SIZE,
      status,
    })
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const getContextByIds = async (
  ids: string[],
): Promise<IContext[] | Error> => {
  const response = await clientWithToken
    .post<{ body: { list: IContext[] } }>(ENDPOINTS.context.contexts, { ids })
    .catch(ensureError);
  return isError(response) ? response : response.data.body.list;
};

export const deleteContexts = async (
  contextIds: string[],
): Promise<unknown | Error> => {
  const url =
    contextIds.length === 1
      ? `${ENDPOINTS.context.context}/${contextIds[0]}`
      : ENDPOINTS.context.contextBulkDelete;
  const response = await clientWithToken
    .delete<unknown>(url, {
      ...(contextIds.length > 1 && { data: contextIds }),
    })
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const getContext = async (id: string): Promise<IContext | Error> => {
  const response = await clientWithToken
    .get<{ body: IContext }>(`${ENDPOINTS.context.context}/${id}`)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const updateContext = async (
  data: IUpdateContextBody,
): Promise<IContext | Error> => {
  const response = await clientWithToken
    .put<{ body: IContext }>(ENDPOINTS.context.context, data)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const getLocations = async (
  query: string,
): Promise<ILocation[] | Error> => {
  const response = await clientWithToken
    .post<{
      body: { items: ILocation[] };
    }>(ENDPOINTS.metadata.locationsFilter, {
      hierarchyToExclude: [],
      title: query,
    })
    .catch(ensureError);
  return isError(response) ? response : response.data.body.items;
};

export const getLocationsByIds = async (
  ids: string[],
): Promise<ILocation[] | Error> => {
  const response = await clientWithToken
    .get<{ body: { items: ILocation[] } }>(
      `${ENDPOINTS.metadata.locations}/${ids}`,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body.items;
};

export const getTargetGroupWeights = async (): Promise<
  { [key: string]: string | number } | Error
> => {
  const response = await clientWithToken
    .get<{
      body: { [key: string]: string | number };
    }>(ENDPOINTS.context.targetGroupsWeight)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const createContext = async (payload: {
  [key: string]: ContextDetailsType;
}): Promise<IContext | Error> => {
  const response = await clientWithToken
    .post<{ body: IContext }>(ENDPOINTS.context.context, payload)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const deleteUtm = async (utmId: string): Promise<unknown | Error> => {
  const response = await clientWithToken
    .delete<{ body: unknown }>(`${ENDPOINTS.context.utm.utm}/${utmId}`)
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const deleteUtms = async (
  utmIds: string[],
): Promise<unknown | Error> => {
  const response = await clientWithToken
    .delete<{ body: unknown }>(`${ENDPOINTS.context.utm.bulk}`, {
      data: utmIds,
    })
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const fetchUtmsByIds = async (
  payload: string[],
): Promise<IUtm[] | Error> => {
  const response = await clientWithToken
    .post<{ body: { list: IUtm[] } }>(ENDPOINTS.context.utm.bulk, payload)
    .catch(ensureError);
  return isError(response) ? response : response.data.body.list;
};

export const createUtm = async (
  payload: IUtmUpdateBody,
): Promise<IUtm | Error> => {
  const response = await clientWithToken
    .post<{ body: IUtm }>(ENDPOINTS.context.utm.utm, payload)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const updateUtm = async (
  payload: IUtmUpdateBody,
): Promise<IUtm | Error> => {
  const response = await clientWithToken
    .put<{ body: IUtm }>(ENDPOINTS.context.utm.utm, payload)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const updateUtmUsage = async (
  payload: IUTMUsageUpdateBody,
): Promise<IUtm | Error> => {
  const response = await clientWithToken
    .put<{ body: IUtm }>(
      `${ENDPOINTS.context.utm.unlink}/${payload.utmId}`,
      payload.usages,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};
