import { Connector } from '@apis/connector';
import { getProduct, Product } from '@apis/products';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { RProduct } from '@redux/slices/product';
import { RTemplate } from '@redux/slices/template';
import { isError } from '@utils/ImproperError';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AllProductsView } from './AllProductsView';
import { RecommendedProductsView } from './RecommendedProductsView';

import './style.scss';

export const SelectProductView: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [viewAll, setViewAll] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState<Product[]>();

  const { items } = useSelector((state) => state.products);

  useEffect(() => {
    setSelectedProduct(void 0);
    if (!items) dispatch(RProduct.resetResults());
    if (!viewAll && !recommendedProducts) {
      Connector.getRecommendedProducts(3)
        .then(async ({ data: { rankedProducts } }) => {
          const products = await Promise.all(
            rankedProducts.map(async ({ id }) => {
              const result =
                items?.find((p) => p.id === id) || (await getProduct(id));
              if (!isError(result)) return result;
              else throw result;
            }),
          );
          setRecommendedProducts(products);
        })
        // On exception, fallback to browse
        .catch(() => setViewAll(true));
    }

    return () => {
      // Reset search in case someone search in all products view
      dispatch(RProduct.setSearchText(''));
    };
  }, [viewAll, dispatch]);

  return (
    <div className="select-product-view">
      <div className="select-product-view-top">
        {viewAll ? (
          <AllProductsView
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        ) : (
          <RecommendedProductsView
            recommendedProducts={recommendedProducts}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
      </div>
      <div className="select-product-view-bottom">
        <PrimaryButton
          onClick={() => setViewAll((viewAll) => !viewAll)}
          variant="green"
        >
          {capitalizeEveryFirstLetter(
            t('testFlow.step1.view' + (viewAll ? 'Recommended' : 'All')),
          )}
        </PrimaryButton>

        <PrimaryButton
          onClick={() => {
            if (!selectedProduct) return;

            dispatch(RProduct.setCurrentItem(selectedProduct));
            dispatch(RTemplate.fetchTemplate(selectedProduct.id, 'product'));
          }}
          disabled={!selectedProduct}
        >
          {capitalizeEveryFirstLetter(t('testFlow.step1.selectProduct'))}
        </PrimaryButton>
      </div>
    </div>
  );
};
