import { TestStrategy } from '@apis/aiService';
import {
  fetchVersion,
  listVersions,
  Version,
  versionListConfig,
} from '@apis/versions';
import { toast } from '@components/ToastNotification/ToastManager';
import { ReferenceVersion } from '@components/VersionReference/VersionReference';
import { ListableActions, ListableState } from '@models/filter';
import { AnyAttributeValue } from '@models/product';
import { registerSideEffects } from '@redux/sideEffects';
import { AppThunk } from '@redux/store';
import { createSlice } from '@reduxjs/toolkit';
import { ensureError, isError } from '@utils/ImproperError';

type VersionState = ListableState<Version, typeof versionListConfig> & {
  currentItem: Version | null;
  /** The item to be modified for creation */
  draftItem: Version.Post | null;
  testStrategy: TestStrategy | null;
  referenceVersion: ReferenceVersion | null;
};

const initialState: VersionState = {
  currentItem: null,
  draftItem: null,
  testStrategy: null,
  referenceVersion: null,
  items: null,
  totalItems: 0,
  sortBy: 'updatedAt',
  sortOrder: 'desc',
  searchText: '',
  selectedItems: [],
  page: 0,
  filters: {
    type: undefined,
    status: undefined,
    'parent.id': undefined,
    'parent.type': undefined,
  },
};

type ListActions = ListableActions<typeof versionListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    setFilter: (
      state,
      {
        payload,
      }: {
        payload: VersionState['filters'];
      },
    ) => {
      caseReducers.resetResults(state);
      state.filters = payload;
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      const filters = { ...state.filters, ...payload };
      caseReducers.resetResults(state);
      state.filters = filters;
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
      state.filters = initialState.filters;
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: Version[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    removeItems: (state, { payload }: { payload: DeepReadonly<Version[]> }) => {
      const { items } = state;
      if (!items) return;
      for (const item of payload) {
        const index = items.findIndex(({ id }) => id === item.id);
        if (index !== -1) {
          items.splice(index, 1);
          state.totalItems--;
        }
      }
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setTestStrategy: (state, { payload }: { payload: TestStrategy | null }) => {
      state.testStrategy = payload;
    },
    setCurrentItem: (state, { payload }: { payload: Version | null }) => {
      state.currentItem = payload;
    },
    setDraftItem: (state, { payload }: { payload: Version.Post | null }) => {
      state.draftItem = payload;
    },
    updateDraftAttribute: (
      state,
      { payload }: { payload: { name: string; value: AnyAttributeValue } },
    ) => {
      if (state.draftItem)
        state.draftItem.attributes[payload.name] = payload.value;
    },
    updateDraftVariantAttribute: (
      state,
      {
        payload,
      }: {
        payload: { variantId: string; name: string; value: AnyAttributeValue };
      },
    ) => {
      if (!state.draftItem) return;

      let map = state.draftItem.variantOverrides[payload.variantId];
      if (!map) map = state.draftItem.variantOverrides[payload.variantId] = {};

      map[payload.name] = payload.value;
    },
    setReferenceVersion: (
      state,
      { payload }: { payload: DeepReadonly<ReferenceVersion> | null },
    ) => {
      state.referenceVersion = payload as ReferenceVersion;
    },
    updateListItem: (
      state,
      { payload }: { payload: Partial<Version> & { id: string } },
    ) => {
      if (!state.items) return;

      const index = state.items.findIndex(({ id }) => id === payload.id);
      if (index === -1) return;

      const item = state.items[index];
      state.items[index] = { ...item, ...payload };
    },
  },
});

let loadingVersionId: string | null = null;

const thunks = {
  loadVersion:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const {
        versions: { currentItem },
      } = getState();
      if (currentItem?.id === id) return; // Already loaded
      if (loadingVersionId === id) return; // Currently loading
      loadingVersionId = id;
      dispatch(actions.setCurrentItem(null));

      const result = await fetchVersion(id);

      // Another version is being loaded since response came in, so abort
      if (loadingVersionId !== id) return;
      else if (isError(result)) toast.show(result);
      else dispatch(actions.setCurrentItem(result));

      loadingVersionId = null;
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

let listAbortController: AbortController | null = null;

registerSideEffects({
  name: 'Fetch next versions',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.setFilter,
    actions.updateFilter,
    actions.setSort,
  ],
  execute: async ({ dispatch, state }) => {
    try {
      if (listAbortController) listAbortController.abort();
      const abortController = new AbortController();
      listAbortController = abortController;

      const versionsResult = await listVersions(state.versions);
      if (abortController.signal.aborted) return;
      if (isError(versionsResult)) return toast.show(versionsResult);

      const { items, totalCount } = versionsResult;
      dispatch(RVersion.addItems(items));
      dispatch(RVersion.setItemCount(totalCount));
    } catch (err) {
      toast.show(ensureError(err));
    }
  },
});

export const RVersion = Object.assign(actions, thunks);

export default reducer;
