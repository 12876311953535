import fiveSecondCountdown from '@assets/illustrations/fiveSecondCountdown.gif';
import { AttributeField } from '@components/ContentEditor/AttributeField/AttributeField';
import { SkeletonSpan } from '@components/SkeletonText/SkeletonText';
import { colors } from '@constants/colors';
import { Modal, RModal } from '@redux/slices/modal';
import { RVersion } from '@redux/slices/version';
import store from '@redux/store';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import ReactHtmlParser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAssets } from '../../hooks/useAssets';
import { AlertIcon } from '../Icons/AlertIcon';
import { CloseIcon } from '../Icons/CloseIcon';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';

import './style.scss';

import './ConfirmModal.scss';
import './EditorModal.scss';
import './TextInputModal.scss';
import './typeA.scss';
import './typeB.scss';
import './UsageModal.scss';
import './VersionPreviewModal.scss';

const ModalComponents: {
  [type in Modal['type']]: (
    props: Extract<Modal, { type: type }>,
  ) => JSX.Element;
} = {
  TypeA: (props) => {
    const { children } = props;
    const dispatch = useDispatch();

    const closeModalIconClick = () => {
      dispatch(RModal.close());
    };

    return (
      <div className="type-a-modal">
        <CloseIcon
          className="close-model-icon"
          onClick={closeModalIconClick}
          role="button"
        />
        {props.icon}
        <h4>{props.heading}</h4>
        <p>{ReactHtmlParser(props?.supportingText || '')}</p>
        {children}
      </div>
    );
  },
  typeB: (props) => {
    const { children } = props;

    return (
      <div className={`type-b-modal ${props?.className || ''}`}>
        {props?.icon ? (
          { icon: props.icon }
        ) : (
          <AlertIcon
            fill={props?.danger ? colors.sizzlingRed : colors.emerald}
          />
        )}
        <div className="modal-text-container">
          <h6>{props?.heading}</h6>
          <p>{props?.supportingText} </p>
        </div>
        {children}
        <div className="modal-buttons-container">
          {props?.buttons.map((button, index) => (
            <PrimaryButton
              size="small"
              variant={button.variant || 'gray'}
              onClick={button.method}
              key={index}
            >
              {button.text}
            </PrimaryButton>
          ))}
        </div>
      </div>
    );
  },
  ConfirmModal: (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const icon =
      props.icon === 'danger' ? (
        <AlertIcon width="2.6875rem" fill={colors.sizzlingRed} />
      ) : (
        props.icon
      );

    return (
      <div className="ConfirmModal ">
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
        {icon}
        <h6>{props.heading}</h6>
        <p>{props.description}</p>
        <div>
          <PrimaryButton
            variant="gray"
            size="small"
            onClick={() => dispatch(RModal.close())}
          >
            {props.cancelLabel || t('global.cancel')}
          </PrimaryButton>
          <PrimaryButton
            variant={props.icon === 'danger' ? 'red' : 'green'}
            size="small"
            onClick={props.onConfirm}
          >
            {props.confirmLabel}
          </PrimaryButton>
        </div>
      </div>
    );
  },
  EditorModal: ({ heading, initialValue, reference, attribute, onSubmit }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { cacheAssets } = useAssets();

    const value = useSelector(
      (s) => s.versions.draftItem?.attributes[attribute.name],
    );

    useEffect(() => {
      dispatch(
        RVersion.updateDraftAttribute({
          name: attribute.name,
          value: initialValue,
        }),
      );
    }, [dispatch, initialValue, attribute.name]);

    useEffect(() => {
      if (!reference) return;

      dispatch(
        RVersion.setReferenceVersion({
          id: '',
          title: '',
          attributes: { [attribute.name]: reference },
        }),
      );
      return () => void dispatch(RVersion.setReferenceVersion(null));
    }, [dispatch, reference, attribute.name]);

    useEffect(() => {
      if (attribute.type === 'assets' && Array.isArray(value))
        cacheAssets(...value);
    }, [value, attribute.type, cacheAssets]);

    useEffect(() => {
      if (attribute.type === 'assets' && Array.isArray(initialValue))
        cacheAssets(...initialValue);
    }, [initialValue, attribute.type, cacheAssets]);

    return (
      <div className={'EditorModal EditorModal-' + attribute.type}>
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
        <h6>{heading}</h6>
        <AttributeField attribute={attribute} />
        <div>
          <PrimaryButton
            variant={'gray'}
            size="small"
            onClick={() => dispatch(RModal.close())}
          >
            {t('global.cancel')}
          </PrimaryButton>
          <PrimaryButton
            variant="green"
            size="small"
            onClick={() => {
              const value =
                store.getState().versions.draftItem?.attributes[attribute.name];
              if (value) onSubmit(value);
            }}
          >
            {capitalizeFirstLetter(t('global.save'))}
          </PrimaryButton>
        </div>
      </div>
    );
  },
  TextInputModal: (props) => {
    const [text, setText] = useState('');
    const dispatch = useDispatch();

    return (
      <div className="TextInputModal">
        {props.icon}
        <input
          type="text"
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <PrimaryButton
          variant="green"
          size="small"
          onClick={() => props.onSubmit(text.trim())}
          disabled={text.length === 0}
        >
          {props.submitLabel}
        </PrimaryButton>
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
      </div>
    );
  },
  UsageModal: (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [items, setItems] = useState<
      Array<{ label: string; link: string } | React.ReactNode>
    >([]);

    useEffect(() => {
      const newItems: typeof items = [];
      for (let i = 0; i < props.usages.length; ++i) {
        const usage = props.usages[i];
        if (usage instanceof Promise) {
          newItems.push(<SkeletonSpan />);
          usage.then((res) => {
            newItems[i] = res;
            setItems([...newItems]);
          });
        } else newItems.push(usage);
      }
    }, [props.usages]);

    return (
      <div className="UsageModal">
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
        <h6>{props.heading}</h6>
        <p>{props.description}</p>
        <table>
          <thead>
            <tr>
              <td>{capitalizeEveryFirstLetter(t('global.usages'))}</td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  {item && typeof item === 'object' && 'label' in item ? (
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {item.label}
                    </a>
                  ) : (
                    item
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PrimaryButton
          variant="gray"
          size="small"
          onClick={() => dispatch(RModal.close())}
        >
          {capitalizeEveryFirstLetter(t('global.ok'))}
        </PrimaryButton>
      </div>
    );
  },
  VersionPreviewModal: () => {
    const { t } = useTranslation();
    return (
      <div className="VersionPreviewModal">
        <img src={fiveSecondCountdown} alt="Preview will we generated soon" />
        <h5>{t('version.generating_preview')}</h5>
        <p>
          {t('version.generating_preview_statement')}
          <strong> {t('version.generating_preview_pop_up_blocker')}</strong>
        </p>
      </div>
    );
  },
  UpgradeRequiredModal: () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
      <ModalComponents.ConfirmModal
        type="ConfirmModal"
        icon={<AlertIcon fill={colors.sizzlingRed} />}
        heading={t('pricing.upgrade_to_publish')}
        description={t('pricing.upgrade_rquired_details')}
        cancelLabel={capitalizeFirstLetter(t('global.not_now'))}
        confirmLabel={capitalizeFirstLetter(t('pricing.go_to_billing'))}
        onConfirm={() => history.push('/settings/billing')}
      />
    );
  },

  custom: (props) => <>{props.children}</>,
};

export const Modals: React.FC = () => {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.modals);
  const location = useLocation();

  useEffect(() => {
    // If the user navigates somewhere else close the modal
    if (location && current) {
      dispatch(RModal.close());
    }
  }, [location]);

  // If no modal is open or modalElement not found, return
  if (!current) return null;

  // TODO better ts type
  const ModalComponent = ModalComponents[current.type] as any;

  return (
    <div id="full-screen-model-with-redux">
      <div className="modal-with-redux" id="modal-with-redux-backdrop">
        <ModalComponent {...current} />
      </div>
    </div>
  );
};
