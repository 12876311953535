import { Connector } from '@apis/connector';
import { ReactComponent as LeftChevron } from '@assets/icons/left-chevron.svg';
import { ReactComponent as HeuristicLogo } from '@assets/logos/logo_horizontal.svg';
import { colors } from '@constants/colors';
import { RProduct } from '@redux/slices/product';
import { RTemplate } from '@redux/slices/template';
import { RVersion } from '@redux/slices/version';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectProductView } from './+page/SelectProductView';
import { SelectExperienceView } from './+page/SelectTestView';
import { VersionView } from './+page/VersionView';

import './style.scss';

export const PageComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onboarded } = useSelector((state) => state.tenant);
  const { currentItem: product } = useSelector((s) => s.products);
  const { testStrategy } = useSelector((s) => s.versions);

  // On load, pre-cache recommended products, clear product, & test strategy selection
  useEffect(() => {
    Connector.getRecommendedProducts(0); // Pre-cache recommended
    dispatch(RProduct.setCurrentItem(null));
    dispatch(RProduct.setVariants(null));
    dispatch(RVersion.setTestStrategy(null));
    dispatch(RVersion.setCurrentItem(null));
  }, [dispatch]);

  const step = {
    index: !testStrategy ? 0 : !product ? 1 : 2,
    component: !testStrategy ? (
      <SelectExperienceView />
    ) : !product ? (
      <SelectProductView />
    ) : (
      <VersionView />
    ),
  };

  const back = () => {
    if (product) {
      dispatch(RProduct.setCurrentItem(null));
      dispatch(RVersion.setDraftItem(null));
      dispatch(RTemplate.setTemplate(null));
    } else if (testStrategy) dispatch(RVersion.setTestStrategy(null));
    else window.history.back();
  };

  return (
    <>
      <nav className="test-flow--nav">
        {onboarded === false && <HeuristicLogo />}
        <ol>
          {[0, 1, 2].map((i) => (
            <li key={i} className={i === step.index ? 'active' : ''}>
              {capitalizeEveryFirstLetter(t(`testFlow.step${i}.name`))}
            </li>
          ))}
        </ol>
      </nav>
      <main className="test-flow--main">
        {(onboarded !== false || testStrategy) && (
          <button className="back-button" onClick={back}>
            <LeftChevron fill={colors.manatte} />
          </button>
        )}
        <h1>
          {capitalizeFirstLetter(t(`testFlow.step${step.index}.heading`))}
        </h1>
        <h2>
          {capitalizeFirstLetter(t(`testFlow.step${step.index}.subheading`))}
        </h2>
        {step.component}
      </main>
    </>
  );
};
